<template>

<div v-if="isReady">

<v-form  ref="form" @submit.prevent="updateIgsn" lazy-validation>

<v-container fill-height>
  <v-row class="justify-center align-center">
    <v-col align="center">

      <v-card width="700" class="mb-4">
        <v-card-title>Main metadata</v-card-title>
        <v-card-text>
<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6">Prefix</v-col><v-col md="6">{{get.prefix.nom}}</v-col></v-row>
 <v-row no-gutters><v-col md="6">Landing page (if not set, use default landing page. Must begin by https://.)</v-col><v-col md="6"><v-text-field id="landingPage" v-model="landingPage"></v-text-field></v-col></v-row>

<v-row no-gutters><v-col md="6">Sample or Item name</v-col><v-col md="6"><v-text-field label="Name" id="name" :rules="rules" v-model="name">{{ get.name }}</v-text-field></v-col></v-row>

<!--
<v-row no-gutters><v-col md="6">IGSN parent</v-col><v-col md="6"><v-text-field id="parent_igsn" type="text" v-model="parent_igsn">{{get.parent_igsn}}</v-text-field></v-col></v-row>
-->
<v-row no-gutters><v-col md="6">IGSN parent</v-col>
  <v-col md="6">
<v-select
            :items="allResourceIdentifier"
             item-text="resourceIdentifier"
            item-value="id"
            v-model="selected_parentIdentifier"
            label="IGSN parent"
          ></v-select>
</v-col></v-row>

<v-row no-gutters><v-col md="6">Visibility</v-col><v-col md="6">

<v-select
            :items="isPublic2"
            v-model="selected_isPublic"
            label="Public ?"
              :rules="[(v) => !!v || 'Field required']"
      required></v-select>

  </v-col></v-row>
<v-row v-if="selected_isPublic === 'Under umbargo' " no-gutters><v-col md="6">Publish date</v-col><v-col md="6"> <input type="date" v-model="publish_date" /></v-col></v-row>
<!-- <v-row no-gutters><v-col md="6">Landing page</v-col><v-col md="6">{{ get.landingPage }}</v-col></v-row> -->
<v-row no-gutters><v-col md="6">Sample type</v-col><v-col md="6">
  <v-select
            :items="sample_type"
            item-text="nom"
            item-value="id"
            v-model="selected_sample_type"
            label="Sample type"
            :rules="[(v) => !!v || 'Field is required']"
            required   ></v-select>

          </v-col></v-row>
<v-row no-gutters><v-col md="6">Material</v-col><v-col md="6"> <v-select
            :items="material"
            item-text="nom"
            item-value="id"
            v-model="selected_material"
            label="Material"
            :rules="[(v) => !!v || 'Field is required']"
      required
          ></v-select></v-col></v-row>

<v-row no-gutters><v-col md="6">Classification</v-col><v-col md="6">
  <v-select
            :items="classification"
             item-text="nom"
            item-value="id"
            v-model="selected_classification"
            label="Classification"
          ></v-select>
  </v-col></v-row>

<v-row no-gutters><v-col md="6">Field name</v-col><v-col md="6"><v-text-field id="field_name" type="text" v-model="field_name" /></v-col></v-row>
<v-row no-gutters><v-col md="6">Age min</v-col><v-col md="6"><v-text-field id="age_min" type="text" v-model="age_min"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Age max</v-col><v-col md="6"><v-text-field id="age_max" type="text" v-model="age_max" /></v-col></v-row>
<v-row no-gutters><v-col md="6">Age unit</v-col><v-col md="6"><v-text-field id="age_unit" type="text" v-model="age_unit"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Geological age</v-col><v-col md="6">

<v-select
            :items="geological_age"
             item-text="nom"
            item-value="id"
            v-model="selected_geological_age"
            label="Geological age"
          ></v-select>

</v-col></v-row>
<v-row no-gutters><v-col md="6">Geological unit</v-col><v-col md="6"><v-text-field id="geological_unit" type="text" v-model="geological_unit"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection method</v-col><v-col md="6"> <v-select
            :items="collection_method"
             item-text="nom"
            item-value="id"
            v-model="selected_collection_method"
            label="Collection method"
          ></v-select> <v-text-field id="collection_method_descr" type="text" v-model="collection_method_descr" /></v-col></v-row>
<v-row no-gutters><v-col md="6">Size</v-col><v-col md="6"><v-text-field id="size" type="text" v-model="size" /></v-col></v-row>
<v-row no-gutters><v-col md="6">Size unit</v-col><v-col md="6"><v-text-field id="size_unit" type="text" v-model="size_unit"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Comments</v-col><v-col md="6"><v-text-field id="sample_comment" type="text" v-model="sample_comment"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Purpose</v-col><v-col md="6"><v-text-field id="purpose" type="text" v-model="purpose"  /></v-col></v-row>

<!--  relatedResources -->
<v-row no-gutters><v-col md="6">Related resources</v-col><v-col md="6">
<br>
      <v-btn @click="addResource">Add a resource</v-btn>
      <br>
  <div
      v-for="(rs, counter) in relatedResources"
      v-bind:key="counter">
        <label for="relatedResource">{{counter+1}}. Related resource :</label>
          <v-text-field label="Name" v-model="rs.relatedResource"  :rules="[(v) => !!v || 'Field is required']" ></v-text-field>

        <v-select
            :items="identifierType"
            item-text="nom"
            item-value="nom"
            v-model="rs.identifierType"
            label="Identifier type"
          ></v-select>

         <v-select
            :items="relationType"
            item-text="nom"
            item-value="nom"
            v-model="rs.relationType"
            label="Relation type"
          ></v-select>
<v-btn @click="deleteRelatedResource(counter)">Remove</v-btn>
  </div>

  </v-col></v-row>

</v-card-text>
</v-card>

<!-- Location -->

<v-card width="700" class="mb-4">
        <v-card-title>Location</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6">Latitude</v-col><v-col md="6"><v-text-field label="Latitude" id="latitude" v-model="latitude"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Latitude end</v-col><v-col md="6"><v-text-field label="Latitude end" id="latitude_end" v-model="latitude_end"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Longitude</v-col><v-col md="6"><v-text-field label="Longitude" id="longitude" v-model="longitude"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Longitude end</v-col><v-col md="6"><v-text-field label="Longitude end" id="longitude_end" v-model="longitude_end"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation</v-col><v-col md="6"><v-text-field label="Elevation" id="elevation" v-model="elevation"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation end </v-col><v-col md="6"><v-text-field label="Elevation end" id="elevation_end" v-model="elevation_end"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation unit</v-col><v-col md="6"><v-text-field label="Elevation unit" id="elevation_unit" v-model="elevation_unit"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Bathy</v-col><v-col md="6"><v-text-field label="Bathy" id="bathy" v-model="bathy"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Bathy unit</v-col><v-col md="6"><v-text-field label="Bathy unit" id="bathy_unit" v-model="bathy_unit"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Navigation type</v-col><v-col md="6">

<v-select
            :items="navigation_type"
             item-text="nom"
            item-value="id"
            v-model="selected_navigation_type"
            label="Navigation type"
          ></v-select>
</v-col></v-row>

<v-row no-gutters><v-col md="6">Physiographic feature</v-col><v-col md="6">

<v-select
            :items="physiographic_feature"
             item-text="nom"
            item-value="id"
            v-model="selected_physiographic_feature"
            label="Physiographic feature"
          ></v-select>

  <v-text-field label="physiographic feature name" id="physiographic_feature_name" v-model="physiographic_feature_name">{{ get.physiographic_feature_name }}</v-text-field></v-col></v-row>

<v-row no-gutters><v-col md="6">Locality</v-col><v-col md="6"><v-text-field label="Locality" id="locality" v-model="location"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Locality description</v-col><v-col md="6"><v-text-field label="Locality description" id="locality_description" v-model="locality_description"/></v-col></v-row>

<v-row no-gutters><v-col md="6">Country</v-col><v-col md="6">
  <v-select
            :items="countries"
             item-text="nom"
            item-value="id"
            v-model="selected_country"
            label="Country"
          ></v-select>
  </v-col></v-row>

<v-row no-gutters><v-col md="6">Location description</v-col><v-col md="6"><v-text-field label="Location description" id="location_description" v-model="location_description"/></v-col></v-row>

</v-card-text>
</v-card>

<v-card width="700" class="mb-4">
        <v-card-title>Collection</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6">Cruise field program</v-col><v-col md="6"><v-text-field label="Cruise field program" id="cruise_field_prgrm" v-model="cruise_field_prgrm"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Platform type</v-col><v-col md="6">
  <!-- <v-text-field label="Platform type" id="platform_type" v-model="platform_type"/> -->
<v-select
            :items="platform_type"
             item-text="nom"
            item-value="id"
            v-model="selected_platform_type"
            label="Platform type"
          ></v-select>

</v-col></v-row>
<v-row no-gutters><v-col md="6">Platform name</v-col><v-col md="6"><v-text-field label="Platform name" id="platform_name" v-model="platform_name"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Platform description</v-col><v-col md="6"><v-text-field label="Platform description" id="platform_descr" v-model="platform_descr"/></v-col></v-row>

<v-row no-gutters><v-col md="6">Launch type</v-col><v-col md="6">

<v-select
            :items="launch_type"
             item-text="nom"
            item-value="id"
            v-model="selected_launch_type"
            label="Launch type"
          ></v-select>

</v-col></v-row>

<v-row no-gutters><v-col md="6">Launch platform name</v-col><v-col md="6"><v-text-field label="Launch platform name" id="launch_platform_name" v-model="launch_platform_name"/></v-col></v-row>

<v-row no-gutters><v-col md="6">Collector</v-col><v-col md="6"><v-text-field label="Collector" id="collector" v-model="collector"  :rules="rules"  /></v-col></v-row>
<v-row no-gutters><v-col md="6">Collector detail</v-col><v-col md="6"><v-text-field label="Collector detail" id="collector_detail" v-model="collector_detail"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection date precision</v-col><v-col md="6"><v-text-field label="Collection date precision" id="collection_date_precision" v-model="collection_date_precision"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection start date</v-col><v-col md="6"><v-text-field label="Collection start date" id="collection_start_date" v-model="collection_start_date"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection end date</v-col><v-col md="6"><v-text-field label="Collection end date" id="collection_end_date" v-model="collection_end_date"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection time</v-col><v-col md="6"><v-text-field label="Collection time" id="collection_time" v-model="collection_time"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection time end</v-col><v-col md="6"><v-text-field label="Collection time end" id="collection_time_end" v-model="collection_time_end"/></v-col></v-row>

</v-card-text>
</v-card>

<v-card width="700" class="mb-4">
        <v-card-title>Curation</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6">Current archive</v-col><v-col md="6"><v-text-field label="Current archive" id="current_archive" v-model="current_archive"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Current archive contact</v-col><v-col md="6"><v-text-field label="Current archive contact" id="current_archive_contact" v-model="current_archive_contact"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Original archive</v-col><v-col md="6"><v-text-field label="Original archive" id="original_archive" v-model="original_archive"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Original archive contact</v-col><v-col md="6"><v-text-field label="Original archive contact" id="original_archive_contact" v-model="original_archive_contact"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth min</v-col><v-col md="6"><v-text-field label="Depth min " id="depth_min" v-model="depth_min"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth max</v-col><v-col md="6"><v-text-field label="Depth max" id="depth_max" v-model="depth_max"/></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth scale</v-col><v-col md="6"><v-text-field label="Depth scale" id="depth_scale" v-model="depth_scale"/></v-col></v-row>
</v-card-text>
</v-card>
</v-col>
</v-row>
</v-container>

      <v-btn class="mb-8 mr-4" style="left: 50%; transform: translateX(-50%)" type="submit">Update</v-btn>
    <div v-if="response">
      <h3>Response from server :</h3>
      <p v-if="success"> {{ success }}</p>
      <pre>{{ response }}</pre>
    </div>

<v-snackbar
v-model="snackbar"
      :timeout="timeout"
      :color="snackbarcolor"
    >
    {{messageRes}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-form>

</div>
</template>

<script>

import moment from 'moment'
import { update, getMetaIGSN, getMaterial, getResourceType, getCountries, getClassification, getCollectionMethod, getPhysiographicFeature, getPlatformType, getNavigationType, getGeologicalAge, getLaunchType, getGeologicalResource, getCoredResource, getAllResourceIdentifier, getIdentifierType, getRelationType } from '@/js/api'

export default {
  name: 'UpdateIgsn',
  props: ['id'],
  data: function () {
    return {
      allResourceIdentifier: [],
      selected_parentIdentifier: null,
      isReady: false,
      timeout: 1000,
      snackbar: false,
      snackbarcolor: 'green',
      messageRes: '',
      get: '',
      params: null,
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 1) || 'Min 1 characters'
      ],
      name: null,
      selected_material: null,
      selected_sample_type: null,
      resourceIdentifier: null,
      landingPage: null,
      defaultLandingPage: null,
      publish_date: new Date().toISOString().slice(0, 10),
      success: '',
      response: '',

      material: [],
      sample_type: [],

      classification: [],

      selected_classification: null,
      classificationUri: null,
      isPublic: [{ id: 1, value: 'Publicly visible' }, { id: 2, value: 'Private' }, { id: 3, value: 'Under umbargo' }],
      selected_isPublic: null,
      isPublic2: ['Publicly visible', 'Private', 'Under umbargo'],

      selected_prefix: null,

      ispublic2: null,
      parent_igsn: '',
      field_name: null,
      age_min: null,
      age_max: null,
      age_unit: null,
      geological_age: [],
      selected_geological_age: [],
      geological_unit: null,
      collection_method: [],

      selected_collection_method: null,
      collection_method_descr: '',
      size: null,
      size_unit: null,
      sample_comment: null,
      purpose: null,

      // relatedResources
      relatedResources: [],
      selected_identifierType: null,
      selected_relationType: null,
      // identifierType: ['Other', 'ARK', 'DOI', 'Handle', 'IGSN', 'ISBN', 'ISNI', 'ISSN', 'LSID', 'ORCID', 'PURL', 'URL', 'URN', 'VIAF', 'arXiv', 'bibcode', 'EAN13', 'EISSN', 'ISTC', 'PMID', 'LISSN', 'UPC'],
      // relationType: ['IsDerivedFrom', 'IsSourceOf', 'IsAggregateOf', 'IsMemberOf', 'HasReferenceResource', 'IsIdenticalTo', 'IsDocumentedBy', 'Participates', 'HasDigitalRepresentation', 'IsDigitalRepresentationOf', 'HasSamplingFeature', 'IsSamplingFeatureOf'],
      identifierType: [],
      relationType: [],
      // location
      latitude: null,
      latitude_end: null,
      longitude: null,
      longitude_end: null,
      elevation: null,
      elevation_end: null,
      elevation_unit: null,
      bathy: null,
      bathy_unit: null,
      navigation_type: [],
      selected_navigation_type: null,
      physiographic_feature: [],
      selected_physiographic_feature: null,
      physiographic_feature_name: null,
      location: null,
      locality_description: null,

      countries: [],

      selected_country: null,
      location_description: null,

      // collection
      cruise_field_prgrm: null,
      platform_type: [],
      selected_platform_type: null,

      platform_name: null,
      platform_descr: null,
      launch_platform_name: null,
      launch_type: [],
      selected_launch_type: null,
      collector: null,
      collector_detail: null,
      collection_date_precision: null,
      collection_start_date: null,
      collection_end_date: null,
      collection_time: null,
      collection_time_end: null,

      // curation
      current_archive: null,
      current_archive_contact: null,
      original_archive: null,
      original_archive_contact: null,
      depth_min: null,
      depth_max: null,
      depth_scale: null

    }
  },
  methods:
  {
    addResource () {
      this.relatedResources.push({
        relatedResource: '',
        identifierType: '',
        relationType: ''
      })
    },

    deleteRelatedResource (counter) {
      this.relatedResources.splice(counter, 1)
    },

    updateIgsn () {
      if (this.$refs.form.validate()) {
        console.log(' avant this.publish_date=', this.publish_date)
        console.log('this.selected_isPublic=', this.selected_isPublic)
        console.log('this.selected_prefix=', this.selected_prefix)

        this.ispublic2 = true
        if (this.selected_isPublic === 'Publicly visible') {
          console.log('yop3')
          this.ispublic2 = true
          this.publish_date = null
        } else if (this.selected_isPublic === 'Private') { // private
          this.ispublic2 = false
          this.publish_date = null
        } else { // under umbargo
          this.ispublic2 = false
        }
        console.log('this.publish_date=', this.publish_date)
        console.log('isPublic=', this.selected_isPublic)
        console.log('relatedResources=', this.relatedResources)
        console.log('this.selected_material=', this.selected_material)
        console.log('this.material=', this.material)
        console.log(this.material.find(item => item.id === this.selected_material))

        console.log('this.selected_classification=', this.selected_classification)
        console.log('this.selected_collection_method=', this.selected_collection_method)

        if (this.landingPage == null || this.landingPage === '') {
          this.landingPage = 'USE DEFAULT LANDING PAGE'
          this.defaultLandingPage = 'true'
        } else {
          this.defaultLandingPage = 'false'
        }

        if (this.selected_parentIdentifier != null) {
          this.parentIgsn = this.allResourceIdentifier.find(x => x.id === this.selected_parentIdentifier).resourceIdentifier
        }

        this.params = {
          name: this.name,
          resourceIdentifier: this.resourceIdentifier,
          prefix: this.selected_prefix,
          // prefix: { id: 1, nom: 'CNRS' },
          userCode: this.selected_prefix.nom,
          visibility: this.selected_isPublic,
          isPublic: this.ispublic2,
          logDate: { eventType: 'updated' },
          defaultLandingPage: this.defaultLandingPage,
          landingPage: this.landingPage,
          material: this.material.find(item => item.id === this.selected_material),
          relatedResources: this.relatedResources,
          resourceType: this.sample_type.find(item => item.id === this.selected_sample_type),
          parentIgsn: this.parentIgsn,
          publishDate: this.publish_date,
          fieldName: this.field_name,
          ageMin: this.age_min,
          ageMax: this.age_max,
          ageUnit: this.age_unit,
          // geologicalAge: this.geological_age,
          geologicalAge: this.geological_age.find(x => x.id === this.selected_geological_age),

          geologicalUnit: this.geological_unit,
          size: this.size,
          sizeUnit: this.size_unit,
          resourceComment: this.sample_comment,
          purpose: this.purpose,

          // location
          latitude: this.latitude,
          latitudeEnd: this.latitude_end,
          longitude: this.longitude,
          longitudeEnd: this.longitude_end,
          elevation: this.elevation,
          elevationEnd: this.elevation_end,
          elevationUnit: this.elevation_unit,
          bathy: this.bathy,
          bathyUnit: this.bathy_unit,
          // navigationType: this.navigation_type,
          navigationType: this.navigation_type.find(x => x.id === this.selected_navigation_type),
          physiographicFeature: this.physiographic_feature.find(x => x.id === this.selected_physiographic_feature),
          physiographicFeatureName: this.physiographic_feature_name,
          location: this.location,
          localityDescription: this.locality_description,
          // country: this.country,
          country: this.countries.find(x => x.id === this.selected_country),
          locationDescription: this.location_description,

          // collection
          cruiseFieldPrgm: this.cruise_field_prgrm,
          // platformType: this.platform_type,
          platformType: this.platform_type.find(x => x.id === this.selected_platform_type),
          launchType: this.launch_type.find(x => x.id === this.selected_launch_type),

          platformName: this.platform_name,
          platformDescription: this.platform_descr,
          launchPlatformName: this.launch_platform_name,

          collector: this.collector,
          collectorDetail: this.collector_detail,
          collectionDatePrecision: this.collection_date_precision,
          collectionStartDate: this.collection_start_date,
          collectionEndDate: this.collection_end_date,
          collectionTime: this.collection_time,
          collectionTimeEnd: this.collection_time_end,

          // curation
          currentArchive: this.current_archive,
          currentArchiveContact: this.current_archive_contact,
          originalArchive: this.original_archive,
          originalArchiveContact: this.original_archive_contact,
          depthMin: this.depth_min,
          depthMax: this.depth_max,
          depthScale: this.depth_scale,
          classification: this.classification.find(x => x.id === this.selected_classification),

          collectionMethod: this.collection_method.find(x => x.id === this.selected_collection_method),
          collectionMethodDesc: this.collection_method_descr
        }

        /*
        if (this.selected_classification != null) {
          this.params.classificationses = [{ classification: this.selected_classification, classificationUri: this.classificationUri }]
        } else {
          this.params.classificationses = []
        }
        */

        /* if (this.selected_collection_method != null) {
          if (this.collection_method_descr == null) {
            this.collection_method_descr = ''
          }
          this.params.collection_method = { collection_method: this.selected_collection_method, collection_method_descr: this.collection_method_descr }
        } */

        update(this.resourceIdentifier, this.params).then(result => {
          this.response = result.data
          console.log('this.response=', this.response)

          this.success = 'IGSN successfully updated'
          this.messageRes = this.success
          this.snackbar = true
          this.snackbarcolor = 'green'

          this.$router.push({ path: '/igsn/' + this.resourceIdentifier })
        }).catch(error => {
          this.success = 'ERROR'
          this.messageRes = this.success + ' ' + error.response.data.errors
          this.snackbar = true
          this.snackbarcolor = 'red'
          console.error('Error in update : ' + error)
        })
      } else {
        this.messageRes = 'Field(s) required'
        this.snackbar = true
        this.snackbarcolor = 'orange'
      }
    },
    getIgsn (id) {
      getMetaIGSN(id).then(result => {
        console.log(result)
        console.log('getigsn ispublic=', result.isPublic)
        this.get = result
        this.name = result.name

        if (result.publishDate != null) {
          this.publish_date = moment(result.publishDate).format('YYYY-MM-DD')
          // this.publish_date = moment(result.publish_date).format('DD/MM/AAAA')
          // this.publish_date = moment(result.publish_date).format('DD-MM-YYYY')
          // this.publish_date = result.publish_date
          console.log('kk=', this.publish_date)
        } else {
          this.publish_date = new Date().toISOString().slice(0, 10)
        }

        this.selected_prefix = result.prefix
        // this.parent_igsn = result.parentIgsn

        if (result.material != null) {
          this.selected_material = result.material.id
        }

        if (result.isPublic === true) {
          this.selected_isPublic = 'Publicly visible'
        } else if (result.isPublic === false && result.publish_date == null) {
          this.selected_isPublic = 'Private'
        }
        if (result.publishDate != null) {
          this.selected_isPublic = 'Under umbargo'
        }

        console.log('this.publish_date=', this.publish_date)

        this.selected_sample_type = result.resourceType.id
        this.resourceIdentifier = result.resourceIdentifier
        console.log('this.get=', this.get)
        console.log('name=', this.name)

        if (result.classification != null) {
          this.selected_classification = result.classification.id
        }

        if (result.navigationType != null) {
          this.selected_navigation_type = result.navigationType.id
        }

        if (result.platformType != null) {
          this.selected_platform_type = result.platformType.id
        }

        if (result.launchType != null) {
          this.selected_launch_type = result.launchType.id
        }

        if (result.geologicalAge != null) {
          this.selected_geological_age = result.geologicalAge.id
        }

        this.field_name = result.fieldName
        /* this.age_min = result.ageMin
        this.age_max = result.ageMax
        this.age_unit = result.ageUnit
        this.geological_age = result.geologicalAge
        this.geological_unit = result.geologicalUnit */
        if (result.collectionMethod != null) {
          this.selected_collection_method = result.collectionMethod.id
        }
        this.collection_method_descr = result.collectionMethodDesc

        this.size = result.size
        this.size_unit = result.sizeUnit
        this.sample_comment = result.resourceComment
        this.purpose = result.purpose
        this.relatedResources = result.relatedResources
        console.log('this.relatedResources=', this.relatedResources)

        this.landingPage = result.landingPage

        // location
        this.latitude = result.latitude
        this.latitude_end = result.latitudeEnd
        this.longitude = result.longitude
        this.longitude_end = result.longitudeEnd
        this.elevation = result.elevation
        this.elevation_end = result.elevationEnd
        this.elevation_unit = result.elevationUnit
        this.bathy = result.bathy
        this.bathy_unit = result.bathyUnit
        if (result.physiographicFeature != null) {
          this.selected_physiographic_feature = result.physiographicFeature.id
        }
        this.physiographic_feature_name = result.physiographicFeatureName
        this.location = result.location
        this.locality_description = result.localityDescription
        if (result.country != null) {
          this.selected_country = result.country.id
        }
        this.location_description = result.locationDescription

        // collection
        this.cruise_field_prgrm = result.cruiseFieldPrgm

        this.platform_name = result.platformName
        this.platform_descr = result.platformDescription
        this.launch_platform_name = result.launchPlatformName
        this.collector = result.collector
        this.collector_detail = result.collectorDetail
        this.collection_date_precision = result.collectionDatePrecision
        this.collection_start_date = result.collectionStartDate
        this.collection_end_date = result.collectionEndDate
        this.collection_time = result.collectionTime
        this.collection_time_end = result.collectionTimeEnd

        // curation
        this.current_archive = result.currentArchive
        this.current_archive_contact = result.currentArchiveContact
        this.original_archive = result.originalArchive
        this.original_archive_contact = result.originalArchiveContact
        /*
        this.depth_min = result.depthMin
        this.depth_max = result.depthMax
        this.depth_scale = result.depthScale
        */
      })
        .catch(error => {
          console.error('Error in getMetaIGSN : ' + error)
        })

      getAllResourceIdentifier().then(result => {
        console.log(result)
        this.allResourceIdentifier = result.data

        if (this.get.parentIgsn != null) {
          this.selected_parentIdentifier = this.allResourceIdentifier.find(x => x.resourceIdentifier === this.get.theParentIgsn).id
          console.log('this.selected_parentIdentifier=', this.selected_parentIdentifier)
        }
      })
        .catch(error => {
          console.error('Error in getAllResourceIdentifier : ' + error)
        })

      getGeologicalResource(id).then(result => {
        console.log(result)
        this.age_min = result.ageMin
        this.age_max = result.ageMax
        this.age_unit = result.ageUnit
        this.selected_geological_age = result.geologicalAge
        this.geological_unit = result.geologicalUnit
      })
        .catch(error => {
          console.error('Error in getGeologicalResource : ' + error)
        })

      getCoredResource(id).then(result => {
        console.log(result)
        this.depth_min = result.depthMin
        this.depth_max = result.depthMax
        this.depth_scale = result.depthScale
      })
        .catch(error => {
          console.error('Error in getCoredResource : ' + error)
        })

      getMaterial().then(result => {
        console.log('material=', result)
        this.material = result
      })
        .catch(error => {
          console.error('Error in getMaterial : ' + error)
        })

      getResourceType().then(result => {
        console.log('sample_type=', result)
        this.sample_type = result
      })
        .catch(error => {
          console.error('Error in getResourceType : ' + error)
        })

      getCountries().then(result => {
        console.log('countries=', result)
        this.countries = result
      })
        .catch(error => {
          console.error('Error in getCountries : ' + error)
        })

      getIdentifierType().then(result => {
        console.log('identifierType=', result)
        this.identifierType = result
      })
        .catch(error => {
          console.error('Error in getIdentifierType : ' + error)
        })

      getRelationType().then(result => {
        console.log('relationType=', result)
        this.relationType = result
      })
        .catch(error => {
          console.error('Error in getRelationType : ' + error)
        })

      getCollectionMethod().then(result => {
        console.log('collection_method=', result)
        this.collection_method = result
      })
        .catch(error => {
          console.error('Error in getCollectionMethod : ' + error)
        })

      getClassification().then(result => {
        console.log('classification=', result)
        this.classification = result
      })
        .catch(error => {
          console.error('Error in getClassification : ' + error)
        })

      getPhysiographicFeature().then(result => {
        console.log('physiographic feature=', result)
        this.physiographic_feature = result
      })
        .catch(error => {
          console.error('Error in getClassification : ' + error)
        })

      getNavigationType().then(result => {
        console.log('navigation type=', result)
        this.navigation_type = result
      })
        .catch(error => {
          console.error('Error in getNavigationType : ' + error)
        })

      getLaunchType().then(result => {
        console.log('launch type=', result)
        this.launch_type = result
      })
        .catch(error => {
          console.error('Error in getLaunchType : ' + error)
        })

      getGeologicalAge().then(result => {
        console.log('geological age=', result)
        this.geological_age = result
      })
        .catch(error => {
          console.error('Error in getGeologicalAge : ' + error)
        })

      getPlatformType().then(result => {
        console.log('platform type=', result)
        this.platform_type = result
        this.isReady = true
      })
        .catch(error => {
          console.error('Error in getPlatformType : ' + error)
        })

      console.log('isReady=', this.isReady)
    }
  },
  created () {
    this.getIgsn(this.id)
  }

}

</script>
<style scoped>

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
