<template>

<v-app>
<v-toolbar flat src="@/assets/fond.png">

    <v-img src="./assets/cnrs.png" max-width="40" max-heigth="40"  class="mr-4"></v-img>
    <v-img src="./assets/dtINSU-FT-B-petit.png" max-width="40" max-heigth="40"  class="mr-1"></v-img>

    <span class="text-h6 white--text ml-4 mr-4 mb-2">
             {{ $route.meta.title }} {{ $route.params.id }}
</span>
<v-spacer></v-spacer>
<v-btn  to="/home" class="mr-1">Home</v-btn>
   <v-btn to="/news/"  v-if="isLoggedIn" class="mr-1">News</v-btn>
   <v-btn to="/asearch/"  class="mr-1">Search</v-btn>
   <v-btn to="/validation/"  class="mr-1">Validation</v-btn>
  <v-btn  to="/private/" v-if="isLoggedIn" class="mr-1">My samples</v-btn>
  <v-btn  to="/myfiles/" v-if="isLoggedIn" class="mr-1">My files</v-btn>
  <v-btn to="/register/" v-if="isLoggedIn" class="mr-1">Register sample</v-btn>
     <span class="black--text mr-4 ml-4" v-if="isLoggedIn">
      <span class="white--text">Welcome <a href="/#/user/">{{ user }}</a></span>
     </span>
     <v-btn to="/login" v-if="!isLoggedIn" class="mr-1">Login</v-btn>
     <v-btn  to="/home" v-else v-on:click="handleLogout" class="mr-1">Logout</v-btn>
     <v-btn  to="/aide/">Help</v-btn>

    </v-toolbar>

<v-main>
      <router-view/>
</v-main>

    <v-footer padless>
      <v-col
      class="text-left"
      cols="10"
    >
    If you would like further information or have any questions please contact uar855.support.dev@AT@cnrs.fr
  </v-col><v-col  class="text-right"><router-link  to="/changelog" >ChangeLog</router-link></v-col>

  </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  computed: {
    user () {
      return this.$store.getters.user
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    handleLogout (event) {
      console.log('handleLogout')
      event.preventDefault()
      this.$store.dispatch('logout')
    }
  }

}

</script>
<style scoped>

.containerkb {
  position: relative;
  text-align: center;
  color: white;
}
</style>
