<template>
<div>
<MyLoader v-if="isLoading" style="display: inline" />
    <MyLoader v-else style="display: none" />

  <div  v-if="isReady">
    <v-card class="mb-6 mt-6 ml-6 mr-6">
  <v-card-text>
<p>Welcome to the CNRS allocating agent service.</p>
    <p>Currently, this allocating agent has registered <strong>{{ nbCodes  }} </strong>IGSN codes.</p>

<p>This International Geo Sample Number (IGSN) service is managed and operated by the Division Technique de l'INSU (DTINSU).
                          <a href="http://www.dt.insu.cnrs.fr">Read more about DTINSU...</a></p>
                      </v-card-text>

</v-card>
<!--
<l-map  class="map" :center="center" :zoom="zoom" ref="map" >
  <l-tile-layer :url="url"></l-tile-layer>
  <l-marker  v-for="m in marks"  :key="m.id" :lat-lng="m.coordinates" >
<l-icon ref="icon">
  <img class="restaurant-icon" :src="m.imageUrl" :title="m.title"/>
  </l-icon>
  </l-marker>

   </l-map>
  -->

  </div>
</div>
</template>

<script>

import MyLoader from '@/components/MyLoader.vue'
/*
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'

import 'leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import { getNumberTotalCodes, getAllPublicIgsnForMap } from '@/js/api'
// import L from 'leaflet'
import { MAIN_URL } from '@/js/constants'
import { getNumberTotalCodes } from '@/js/api'
*/
import { getNumberTotalCodes } from '@/js/api'

/*
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
*/

export default {
  name: 'Public',
  /*  components: {
    MyLoader,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  }, */
  components: {
    MyLoader
  },
  data () {
    return {
      // isReady: false,
      isReady: true,
      marks: [],
      // isLoading: true,
      isLoading: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [42, 6],
      zoom: 2,
      igsns: [],
      nbCodes: 0
    }
  },
  created () {
    console.log('created')
    getNumberTotalCodes().then(result => {
      this.nbCodes = result.data.nbCodes
      // console.log(result.data)
    })
      .catch(error => {
        console.error('Error in getNumberTotalCodes : ' + error)
      })
    /*
    getAllPublicIgsnForMap().then(result => {
      this.igsns = result.data
      // console.log('result.data 1=', result.data)

      for (var i = 0; i < this.igsns.length; i++) {
        if (this.igsns[i].latitude != null && this.igsns[i].latitude !== 'NaN' && this.igsns[i].longitude != null && this.igsns[i].longitude !== 'NaN') {
          this.marks.push({ id: i, title: 'IGSN : ' + this.igsns[i].resourceIdentifier + '\n Name : ' + this.igsns[i].name, imageUrl: require('@/assets/pin.png'), coordinates: [Number(this.igsns[i].latitude), Number(this.igsns[i].longitude)], landingPage: MAIN_URL + '/#/igsn/' + this.igsns[i].resourceIdentifier })
        }
      }

      // console.log('this.marks=', this.marks)
      this.isReady = true
      this.isLoading = false
    })
      .catch(error => {
        console.error('error in  getAllPublicIgsnForMap : ' + error)
      })
      */
  }
}
</script>

<style scoped>

.restaurant-icon {
    height: 5px;
    width: auto;
  }
.map {
 height:600px;
width: 80%;
display: block;
    margin: 0 auto;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
