<template>

  <div>

 <MyLoader v-if="isLoading"  style="display:inline;" />
<MyLoader v-else  style="display:none;" />

   <v-form ref="form" @submit.prevent="handleXLS" lazy-validation>

  <v-container>
  <v-row no-gutters>
  <v-col md="6">
    <v-file-input placeholder="Please upload the XLS file to check"  v-model="checkXLS"    :rules="rules" ></v-file-input></v-col>
    <v-col md="6"><v-btn @click="handleXLS">Submit</v-btn>
    </v-col>
    </v-row>

</v-container>
</v-form>

<div v-if="response">
      <h3>Response from server :</h3>
      <p v-if="success"> {{ success }}</p>
      <pre>{{ response }}</pre>
    </div>

<v-snackbar
v-model="snackbar"
      :timeout="timeout"
      :color="snackbarcolor"
    >
    {{messageRes}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

<br>
</div>

</template>

<script>
import { postCheckXLS, getCSRFToken } from '@/js/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  name: 'Validation',
  components: {
    MyLoader
  },
  props: {
  },
  data: function () {
    return {
      rules: [
        v => !!v || 'File is required'],
      snackbar: false,
      snackbarcolor: 'green',
      messageRes: '',
      checkXLS: null,
      succes: '',
      response: '',
      params: null,
      timeout: 2000,
      isLoading: false,
      csrfToken: ''
    }
  },
  methods:
  {
    handleXLS () {
      if (!this.$refs.form.validate()) {
        this.messageRes = 'File required'
        this.snackbar = true
        this.snackbarcolor = 'orange'
      } else {
        this.isLoading = true
        this.params = { sub: 0, tuser: this.user }
        const payload = JSON.stringify(this.params)
        const bufferObject = Buffer.from(payload, 'utf-8')

        const file = new FormData()
        file.append('params', bufferObject)

        console.log('this.checkXLS=', this.checkXLS)
        console.log('this.checkXLS.type=', this.checkXLS.type)
        if (this.checkXLS.type !== 'application/vnd.ms-excel') {
          this.snackbar = true
          this.snackbarcolor = 'red'
          this.messageRes = 'It must be a XLS file'
          return false
        }
        file.append('file', this.checkXLS)
        console.log('file=', file)
        postCheckXLS(file, this.csrfToken).then(result => {
          console.log('File OK')
          this.isLoading = false
          console.log('result=', result)
          this.messageRes = result.data.message
          this.snackbar = true
          this.snackbarcolor = 'green'
        }).catch(error => {
          console.error('Error in postCheckXLS ' + error)
          this.isLoading = false
          console.log('error.response=', error.response)
          console.log('error.response.data=', error.response.data)
          this.messageRes = error.response.data.message
          this.snackbar = true
          this.snackbarcolor = 'red'
        })
      }
    }
  },
  mounted () {
  },

  created () {
    getCSRFToken().then(result => {
      console.log('getCSRF=', result)
      this.csrfToken = result.data.csrftoken
      console.log('this.csrftoken=', this.csrfToken)
    })
      .catch(error => {
        console.error('Error in getCSRFToken : ' + error)
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
