<template>

  <div>

<v-card v-if="isReady" class="mb-6 mt-6 ml-6 mr-6">
  <v-card-text>

    <span v-if="res">
{{ res.user.first_name }} {{ res.user.last_name }}
</span>
<v-list>
    <v-list-item>
      - Number of IGSN codes : <span v-for="item in totalcodes" :key="item.resource__prefix__nom">{{ item.resource__prefix__nom }} ({{ item.prefixCount }}) &nbsp;</span>
    </v-list-item>
 <v-list-item>
      - Allowed prefix : <span v-for="item in userprefix" :key="item.id">{{ item.nom }}&nbsp;</span>
    </v-list-item>
    <v-list-item>
     <!-- - <a href="/chpwd/"> Change password</a>-->
    </v-list-item>

    </v-list>
                      </v-card-text>

</v-card>
  </div>

</template>

<script>
import { getUserInfo, getTotalCodes, getUserPrefix } from '@/js/api'

export default {

  name: 'User',
  components: {},
  data () {
    return {
      res: null,
      userprefix: null,
      totalcodes: [],
      isReady: false
    }
  },
  created () {
    getUserInfo().then(result => {
      console.log(result)
      this.res = result.data
    }).catch(error => {
      console.error('Error in getUserInfo : ' + error)
    })

    getTotalCodes().then(result => {
      console.log(result)
      this.totalcodes = result.data
    }).catch(error => {
      console.error('Error in getTotalCodes : ' + error)
    })

    getUserPrefix().then(result => {
      console.log(result)
      this.userprefix = result.data
      console.log('this.userprefix=', this.userprefix)
      this.isReady = true
    }).catch(error => {
      console.error('Error in getUserPrefix : ' + error)
    })
  }
}

</script>

<style>

</style>
