import Vue from 'vue'
import Vuex from 'vuex'

import { loginAPI } from '@/js/api'
import { USERNAME } from '@/js/constants'

Vue.config.devtools = true

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isLoggedIn: false
  },

  mutations: {
    loginSuccess (state, userId) {
      console.log('loginSuccess userid=', userId)
      state.user = localStorage.getItem(USERNAME)
      state.isLoggedIn = true
    },
    logout (state) {
      console.log('store logout')
      state.user = null
      state.isLoggedIn = false
    },
    initialiseStore (state) {
      console.log('In initialiseStore')
      console.log('localStorage.getItem(USERNAME)=', localStorage.getItem(USERNAME))

      if (localStorage.getItem(USERNAME) !== null) {
        console.log('okay')
        state.user = localStorage.getItem(USERNAME)
        state.isLoggedIn = true
      }
    }
  },

  actions: {
    async login ({ commit }, { username, password }) {
      try {
        const response = await loginAPI(username, password)
        if (response.data.name !== undefined) {
          console.log('login ok')
          console.log('response.data.name=', response.data.name)
          localStorage.setItem(USERNAME, response.data.name)
          commit({ type: 'loginSuccess', username })
        } else {
          commit({ type: 'logout' })
          return Promise.reject(Error('bad pass'))
        }
      } catch (error) {
        commit({ type: 'logout' })
        return Promise.reject(error)
      }
    },

    async logout ({ commit }) {
      console.log('in async logout ({ commit }) {')
      try {
        localStorage.removeItem(USERNAME)
        commit({ type: 'logout' })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

  },

  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user
  },
  setters: {
  }

})
