<template>
  <div v-if="isReady2">

<h3 style="color:red;text-align:center;margin-bottom:40px;" id="deprecated" v-if="state == 'deprecated'" >Deprecated</h3>

<v-container>
  <v-row>
    <v-col>

      <v-card class="mb-4">
        <v-card-title>Main metadata</v-card-title>
        <v-card-text>

          <v-row><v-col></v-col>
            <v-col>
    <qrcode-vue :value="this.thref" :size="100" level="L" />
            </v-col>
            <v-col></v-col>
  </v-row>
<v-row no-gutters ><v-col >Prefix</v-col><v-col class="font-weight-bold">{{ igsn.prefix.nom }}</v-col></v-row>
<v-row no-gutters ><v-col >Sample or Item name</v-col><v-col class="font-weight-bold">{{ igsn.name }}</v-col>

</v-row>

<v-row no-gutters >
  <v-col >Other names of the sample</v-col>
  <v-col class="font-weight-bold">{{ igsn.otherNames }}</v-col>
</v-row>

<v-row no-gutters ><v-col >IGSN parent</v-col><v-col class="font-weight-bold"><a @click=goToIgsn(igsn.theParentIgsn)>{{ igsn.theParentIgsn }}</a></v-col></v-row>
<v-row no-gutters ><v-col >Childrens</v-col><v-col class="font-weight-bold">
<ul v-for="c in childrens" :key="c.resourceid">
    <li><a @click=goToIgsn(c.resourceIdentifier)>{{ c.resourceIdentifier }}</a></li>
</ul>
  </v-col></v-row>
<v-row no-gutters ><v-col >Siblings</v-col><v-col class="font-weight-bold">
<ul v-for="c in brosis" :key="c.resourceid">
  <li><a @click=goToIgsn(c.resourceIdentifier)>{{ c.resourceIdentifier }}</a></li>
</ul>
  </v-col></v-row>
<v-row no-gutters ><v-col >Public ?</v-col><v-col class="font-weight-bold">{{ igsn.isPublic }}</v-col></v-row>
<v-row v-if="! igsn.isPublic" no-gutters ><v-col >Publish date</v-col><v-col class="font-weight-bold">{{ pubdate }}</v-col></v-row>
<v-row no-gutters ><v-col >Landing page</v-col><v-col class="font-weight-bold"><a :href=igsn.landingPage>{{ igsn.landingPage }}</a></v-col></v-row>
<v-row no-gutters ><v-col >IGSN Handle</v-col><v-col class="font-weight-bold"><a :href=thref>{{ thref }}</a></v-col></v-row>
<v-row no-gutters ><v-col >Sample type</v-col><v-col class="font-weight-bold"><span v-if="igsn.resourceType">{{ igsn.resourceType.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Material</v-col><v-col class="font-weight-bold"><span v-if="igsn.material">{{ igsn.material.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Classification</v-col><v-col class="font-weight-bold">
   <span v-if="igsn.classification">{{ igsn.classification.nom }}</span>
  </v-col></v-row>
<v-row no-gutters ><v-col >Field name</v-col><v-col class="font-weight-bold">{{ igsn.fieldName }}</v-col></v-row>
<v-row no-gutters ><v-col >Age min</v-col><v-col class="font-weight-bold">{{ ageMin }}</v-col></v-row>
<v-row no-gutters ><v-col >Age max</v-col><v-col class="font-weight-bold">{{ ageMax }}</v-col></v-row>
<v-row no-gutters ><v-col >Age unit</v-col><v-col class="font-weight-bold">{{ ageUnit }}</v-col></v-row>
<v-row no-gutters ><v-col >Geological age</v-col><v-col class="font-weight-bold"><span v-if="geologicalAge">{{ geologicalAge.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Geological unit</v-col><v-col class="font-weight-bold">{{ geologicalUnit }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection method</v-col><v-col class="font-weight-bold"><div v-if="igsn.collectionMethod">{{ igsn.collectionMethod.nom }} : {{igsn.collectionMethodDesc}}</div> </v-col></v-row>
<v-row no-gutters ><v-col >Size</v-col><v-col class="font-weight-bold">{{ igsn.size }}</v-col></v-row>
<v-row no-gutters ><v-col >Size unit</v-col><v-col class="font-weight-bold">{{ igsn.sizeUnit }}</v-col></v-row>
<v-row no-gutters ><v-col >Comments</v-col><v-col class="font-weight-bold">{{ igsn.resourceComment }}</v-col></v-row>
<v-row no-gutters ><v-col >Purpose</v-col><v-col class="font-weight-bold">{{ igsn.purpose }}</v-col></v-row>
<v-row no-gutters ><v-col >Log Info</v-col><v-col class="font-weight-bold"><div v-if="igsn.logDate">{{igsn.logDate.eventType}}: {{igsn.logDate.logDate}}</div></v-col></v-row>
<v-row no-gutters ><v-col >Related resources</v-col><v-col class="font-weight-bold">
  <ul v-for="c in igsn.relatedResources" :key="c.relatedResource">
<li>{{ c.relatedResource}} : {{ c.relationType }} {{ c.identifierType }}</li>
    </ul>

  </v-col></v-row>
</v-card-text>
</v-card>

<v-card  class="mb-4">
        <v-card-title>Location</v-card-title>
      <v-card-text>
<v-row no-gutters ><v-col >Latitude</v-col><v-col class="font-weight-bold">{{ igsn.latitude }}</v-col></v-row>
<v-row no-gutters ><v-col >Latitude end</v-col><v-col class="font-weight-bold">{{ igsn.latitudeEnd }}</v-col></v-row>
<v-row no-gutters ><v-col >Longitude</v-col><v-col class="font-weight-bold">{{ igsn.longitude }}</v-col></v-row>
<v-row no-gutters ><v-col >Longitude end</v-col><v-col class="font-weight-bold">{{ igsn.longitudeEnd }}</v-col></v-row>
<v-row no-gutters ><v-col >Elevation</v-col><v-col class="font-weight-bold">{{ igsn.elevation }}</v-col></v-row>
<v-row no-gutters ><v-col >Elevation end </v-col><v-col class="font-weight-bold">{{ igsn.elevationEnd }}</v-col></v-row>
<v-row no-gutters ><v-col >Elevation unit</v-col><v-col class="font-weight-bold">{{ igsn.elevationUnit }}</v-col></v-row>
<v-row no-gutters ><v-col >Bathy</v-col><v-col class="font-weight-bold">{{ igsn.bathy }}</v-col></v-row>
<v-row no-gutters ><v-col >Bathy unit</v-col><v-col class="font-weight-bold">{{ igsn.bathyUnit }}</v-col></v-row>
<v-row no-gutters ><v-col >Navigation type</v-col><v-col class="font-weight-bold"><span v-if="igsn.navigationType">{{ igsn.navigationType.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Physiographic feature</v-col><v-col class="font-weight-bold"><span v-if="igsn.physiographicFeature">{{ igsn.physiographicFeature.nom }},{{ igsn.physiographicFeatureName }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Locality</v-col><v-col class="font-weight-bold">{{ igsn.location }}</v-col></v-row>
<v-row no-gutters ><v-col >Locality description</v-col><v-col class="font-weight-bold">{{ igsn.localityDescription }}</v-col></v-row>
<v-row no-gutters ><v-col >Country</v-col><v-col class="font-weight-bold"><span v-if="igsn.country">{{ igsn.country.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Location description</v-col><v-col class="font-weight-bold">{{ igsn.locationDescription }}</v-col></v-row>

<v-row><v-col></v-col><v-col><div id="mymap">
<l-map class="map" v-if="isReady"
:center="lecentre"
   :zoom="zoom"
   ref="map"
   @update:zoom="zoomUpdated"
   @update:center="centerUpdated"
 >
   <l-tile-layer :url="url">
   </l-tile-layer>
 <l-marker  v-for="m in marker"  :key="m.id" :lat-lng="m.coordinates">
   <l-icon ref="icon">
 <img class="restaurant-icon" :src="m.imageUrl"/>
  </l-icon>
</l-marker>
   </l-map>
</div></v-col><v-col></v-col></v-row>

</v-card-text>
</v-card>

<v-card  class="mb-4">
        <v-card-title>Collection</v-card-title>
      <v-card-text>
<v-row no-gutters ><v-col >Cruise field program</v-col><v-col class="font-weight-bold">{{ igsn.cruiseFieldPrgm }}</v-col></v-row>
<v-row no-gutters ><v-col >Platform type</v-col><v-col class="font-weight-bold"><span v-if="igsn.platformType">{{ igsn.platformType.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Platform name</v-col><v-col class="font-weight-bold">{{ igsn.platformName }}</v-col></v-row>
<v-row no-gutters ><v-col >Platform description</v-col><v-col class="font-weight-bold">{{ igsn.platformDescription }}</v-col></v-row>
<v-row no-gutters ><v-col >Launch type</v-col><v-col class="font-weight-bold"><span v-if="igsn.launchType">{{ igsn.launchType.nom }}</span></v-col></v-row>
<v-row no-gutters ><v-col >Launch platform name</v-col><v-col class="font-weight-bold">{{ igsn.launchPlatformName }}</v-col></v-row>
<v-row no-gutters ><v-col >Collector</v-col><v-col class="font-weight-bold">{{ igsn.collector }}</v-col></v-row>
<v-row no-gutters ><v-col >Collector detail</v-col><v-col class="font-weight-bold">{{ igsn.collectorDetail }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection date precision</v-col><v-col class="font-weight-bold">{{ igsn.collectionDatePrecision }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection start date</v-col><v-col class="font-weight-bold">{{ igsn.collectionStartDate }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection end date</v-col><v-col class="font-weight-bold">{{ igsn.collectionEndDate }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection time</v-col><v-col class="font-weight-bold">{{ igsn.collectionTime }}</v-col></v-row>
<v-row no-gutters ><v-col >Collection time end</v-col><v-col class="font-weight-bold">{{ igsn.collectionTimeEnd }}</v-col></v-row>
</v-card-text>
</v-card>

<v-card  class="mb-4">
        <v-card-title>Curation</v-card-title>
      <v-card-text>
<v-row no-gutters ><v-col >Current archive</v-col><v-col class="font-weight-bold">{{ igsn.currentArchive }}</v-col></v-row>
<v-row no-gutters ><v-col >Current archive contact</v-col><v-col class="font-weight-bold">{{ igsn.currentArchiveContact }}</v-col></v-row>
<v-row no-gutters ><v-col >Original archive</v-col><v-col class="font-weight-bold">{{ igsn.originalArchive }}</v-col></v-row>
<v-row no-gutters ><v-col >Original archive contact</v-col><v-col class="font-weight-bold">{{ igsn.originalArchiveContact }}</v-col></v-row>
<v-row no-gutters ><v-col >Depth min</v-col><v-col class="font-weight-bold">{{ depthMin }}</v-col></v-row>
<v-row no-gutters ><v-col >Depth max</v-col><v-col class="font-weight-bold">{{ depthMax }}</v-col></v-row>
<v-row no-gutters ><v-col >Depth scale</v-col><v-col class="font-weight-bold">{{ depthScale }}</v-col></v-row>
</v-card-text>
</v-card>
</v-col>

</v-row>

<!-- TODO canUpdate
  -->
<v-row v-if="canUpdate"><v-col>
  <v-btn class="mb-8 mr-4" style="left: 50%; transform: translateX(-50%)" v-on:click="Update">Update</v-btn>
</v-col>
 <v-col v-if="state != 'deprecated'" ><v-btn class="mb-8 mr-4" style="left: 50%; transform: translateX(-50%)" v-on:click="deprecateConfirmation=true">Deprecate</v-btn>

 <v-dialog v-model="deprecateConfirmation" persistent max-width="290">
                <v-card>
                    <v-card-title class="error headline" style="font-weight:bold; color:white;">Confirm </v-card-title>
                    <v-card-text>Are you sure you want to deprecade this code ?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="deprecateConfirmation = false">Cancel</v-btn>
                        <v-btn color="error" text @click="Deprecate">Confirm Deprecate</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

</v-col></v-row>

</v-container>

<v-snackbar
v-model="snackbar"
      :timeout="timeout"
      :color="snackbarcolor"
    >
    {{messageRes}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

</div>

</template>

<script>

import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
// import { getMetaIGSN, getChildrens, getBroSis } from '@/js/api'
import { getMetaIGSN, setDeprecate, getChildrens, getBroSis, getCoredResource, getGeologicalResource } from '@/js/api'

import { IGSN_PREFIX } from '@/js/constants'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    QrcodeVue
  },
  data () {
    return {
      snackbar: false,
      snackbarcolor: 'green',
      messageRes: '',
      timeout: 2000,
      align: 'center',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [42, 6],
      zoom: 12,
      marker: [],
      igsn: {},
      isReady: false,
      isReady2: false,
      childrens: [],
      brosis: [],
      thref: null,
      pubdate: '',
      canUpdate: true,
      deprecateConfirmation: false,
      state: null,
      depthMax: null,
      depthMin: null,
      depthScale: null,
      ageMin: null,
      ageMax: null,
      ageUnit: null,
      geologicalUnit: null,
      geologicalAge: null
    }
  },

  computed: {
    lecentre: function () {
      if (this.igsn.latitude == null || this.igsn.longitude == null) { return [42, 6] } else { return [Number(this.igsn.latitude), Number(this.igsn.longitude)] }
    }
  },

  name: 'ViewIgsn',
  props: ['id'],
  methods:
  {
    goToIgsn (id2) {
      console.log('goToIgsn ', id2)
      this.$router.push({ path: '/igsn/' + id2 }).then(() => { this.$router.go() })
    },

    Update (event) {
      console.log('Update')
      // "'/igsn/vue/#/updateigsn/'+item.resourceIdentifier"
      this.$router.push('/updateigsn/' + this.igsn.resourceIdentifier)
    },

    Deprecate (event) {
      console.log('Deprecate')
      this.deprecateConfirmation = false

      setDeprecate(this.igsn.resourceIdentifier).then(result => {
        this.success = 'IGSN successfully deprecated'
        this.messageRes = this.success
        this.snackbar = true
        this.snackbarcolor = 'green'
        this.$router.go()
      }).catch(error => {
        this.success = 'ERROR'
        this.messageRes = error.response.data.errors
        this.snackbar = true
        this.snackbarcolor = 'red'
        console.error('Error in setDeprecate : ' + error)
      })
    },

    zoomUpdated (zoom) {
      this.zoom = zoom
    },
    centerUpdated (center) {
      this.center = center
    }
  },
  created () {
    console.log('created')
    const _this = this
    getMetaIGSN(this.id).then(result => {
      _this.igsn = result
      this.isReady2 = true
      console.log('result=', result)
      this.pubdate = moment(this.igsn.publishDate).format('YYYY-MM-DD')
      if (this.igsn.longitude != null && this.igsn.latitude != null) {
        this.marker = [{ id: 1, imageUrl: require('@/assets/pin.png'), coordinates: [Number(this.igsn.latitude), Number(this.igsn.longitude)] }]
        console.log('marker=', this.marker)
      }
      this.state = result.state
    }).catch(error => {
      console.error('Error in getMetaIGSN : ' + error)
    })

    /// //////////////
    getGeologicalResource(this.id).then(result => {
      console.log(result)
      this.ageMin = result.ageMin
      this.ageMax = result.ageMax
      this.ageUnit = result.ageUnit
      this.geologicalAge = result.geologicalAge
      this.geologicalUnit = result.geologicalUnit
    })
      .catch(error => {
        console.error('Error in getGeologicalResource : ' + error)
      })

    getCoredResource(this.id).then(result => {
      console.log(result)
      this.depthMin = result.depthMin
      this.depthMax = result.depthMax
      this.depthScale = result.depthScale
    })
      .catch(error => {
        console.error('Error in getCoredResource : ' + error)
      })
      /// //////////////

    // childrens
    getChildrens(this.id).then(result => {
      _this.childrens = result.data
      console.log(result.data)
    })
      .catch(error => {
        console.error('Error in getChildrens : ' + error)
      })

    // bro sis
    getBroSis(this.id).then(result => {
      _this.brosis = result.data
      console.log(result.data)
      this.isReady = true
    })
      .catch(error => {
        console.error('Error in getBroSis : ' + error)
      })

    this.thref = 'http://hdl.handle.net/' + IGSN_PREFIX + '/' + this.id
  }

}
</script>

<style>

 #mymap
  {
   width: 400px;
   height: 400px;
 }

  .restaurant-icon {
    height: 5px;
    width: auto;
  }

.loader{
    position : fixed;
    z-index: 9999;
    background : url( "../assets/load.gif" ) 50% 50% no-repeat;
    top : 0px;
    left : 0px;
    height : 100%;
    width : 100%;
    cursor : wait;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
