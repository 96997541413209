<template>
  <div>
    <!-- Private samples -->

    <MyLoader v-if="isLoading || !isChildrensReady" style="display: inline" />
    <MyLoader v-else style="display: none" />

    <div v-if="!isLoading && isChildrensReady">
      <v-container fill-height>
        <v-row class="justify-center align-center">
          <v-col align="center">
            <download-excel :data="filt" :fields="json_fields">
              Download Data
              <img width="25" height="25" src="../assets/download.png" />
            </download-excel>

            <v-progress-linear
              v-if="test"
              indeterminate
              size="15"
              width="3"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <v-row class="mt-4 justify-center align-center">
          <v-col align="center">
            <v-data-table
              ref="kk"
              :headers="headers"
              :items="igsns"
              :server-items-length="nbResources"
              :options.sync="options"
              :search="search"
              @current-items="getFiltered"
              :footer-props="footerOptions"
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <a :href="'/#/igsn/' + item.resource.resourceIdentifier"
                      >View</a
                    >
                  </td>
                  <td>{{ item.resource.name }}</td>
                  <td>{{ item.resource.resourceIdentifier }}</td>
                  <td>{{ item.resource.lastModified | formatDate }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { getAllPrivateIgsnsAPI2, getAllChildrens } from '@/js/api'
// import { getAllPrivateIgsnsAPI2 } from '@/js/api'

import MyLoader from '@/components/MyLoader.vue'

export default {
  name: 'PrivateSamples',
  components: {
    MyLoader
  },
  props: {},
  computed: {},
  data: function () {
    return {
      options: {},
      footerOptions: {
        itemsPerPageOptions: [10, 25, 50, 100, -1]
      },
      igsns: null,
      filt: [],
      test: true,
      nbResources: null,
      sortBy: '',
      sortDesc: false,
      currentPage: 1,
      currentResults: 5,
      childrens: {},
      json_fields: {
        Name: 'resource.name',
        IGSN: 'resource.resourceIdentifier',
        Type: {
          field: 'resource.resourceType',
          callback: (value) => {
            return value.nom
          }
        },
        Material: {
          field: 'resource.material',
          callback: (value) => {
            return value.nom
          }
        },
        Childrens: {
          callback: (value) => {
            return this.childrens[value.resource.resourceIdentifier]
          }
        }
        /* Classif: {
          field: 'classificationses',
          callback: (value) => {
            if (value != null && value.length > 0) {
              console.log('v=', value)
              return `Class 1 : ${value[0].classification}`
            } else { return `Class 1 : ${value}` }
          }
        } */
      },
      isLoading: true,
      isChildrensReady: false,
      search: '',
      headers: [
        { text: '', value: 'action' },
        { text: 'Name', value: 'resource.name' },
        { text: 'Identifier', value: 'resource.resourceIdentifier' },
        { text: 'Updated', value: 'resource.lastModified' }
      ]
    }
  },

  watch: {
    options: {
      handler () {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        console.log('this.options=', this.options)
        console.log(sortBy)
        console.log(sortDesc)
        console.log(page)
        console.log(itemsPerPage)

        console.log('this.options.sortBy=', this.options.sortBy)
        if (this.options.sortBy.length > 0) {
          this.sortBy = this.options.sortBy[0]
        }
        console.log('this.sortBy=', this.sortBy)
        if (this.options.sortDesc.length > 0) {
          this.sortDesc = this.options.sortDesc[0]
        }
        console.log('this.sortDesc=', this.sortDesc)
        console.log('this.options.itemsPerPage=', this.options.itemsPerPage)

        if (this.options.itemsPerPage !== -1) {
          this.getData(page, itemsPerPage, this.sortBy, this.sortDesc)
        }

        if (this.options.itemsPerPage === -1) {
          this.getData(page, this.nbResources, this.sortBy, this.sortDesc)
        }
      },
      deep: true
    }
  },

  methods: {
    getFiltered: function () {
      if (this.$refs.kk) {
        this.filt = this.$refs.kk.$children[0].filteredItems
      }
    },
    getData (page = 1, results = 5, sortBy, sortDesc) {
      console.log('in get data: ', page, results, sortBy, sortDesc)
      this.test = true

      getAllPrivateIgsnsAPI2(page, results, sortBy, sortDesc)
        .then((result) => {
          this.igsns = result.data.results
          this.nbResources = result.data.count
          this.isLoading = false
          this.test = false
          this.filt = this.igsns
        })
        .catch((error) => {
          console.error('Error in getAllPrivateIgsns ' + error)
        })
    }
    /*
    updatePage (pagination) {
      console.log('in updatePage')
      console.log('updatePage ', pagination)
      console.log('updatePage this.sortBy=', this.sortBy)
      console.log('updatePage this.sortDesc=', this.sortDesc)
      const { itemsPerPage: results, page } = pagination
      this.pagination = pagination

      if (results === -1) {
        // this.getData(page, this.nbResources, this.sortBy, this.sortDesc)
        this.currentPage = page
        this.currentResults = this.nbResources
      } else {
        // this.getData(page, results, this.sortBy, this.sortDesc)
        this.currentPage = page
        this.currentResults = results
      }
    } */
  },
  created () {
    this.isLoading = true

    getAllChildrens()
      .then((result) => {
        this.childrens = result.data
        this.isChildrensReady = true
      })
      .catch((error) => {
        console.error('Error in getAllChildrens : ' + error)
      })
  },
  mounted () {
    this.getData(1, 5, '', '')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
