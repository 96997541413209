import axios from 'axios'
// import store from '@/store'

import { API_URL, API_URL2 } from '@/js/constants'

export const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 10000000
})

export const apiClient2 = axios.create({
  baseURL: API_URL2,
  timeout: 10000000
})
export const apiClientCred = axios.create({
  baseURL: API_URL,
  timeout: 10000000,
  withCredentials: true
})

apiClientCred.interceptors.response.use((response) => {
  console.log('in axios.interceptors.response.use((response)', response.data)

  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  console.log('error.response=', error.response)

  console.log('Promise.reject(error) resp')
  return Promise.reject(error)
})

apiClient.interceptors.response.use((response) => {
  console.log('in no cred axios.interceptors.response.use((response)', response.data)

  return response
}, (error) => {
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error.message)
})
