import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Private from '@/views/Private.vue'
import MyFiles from '@/views/MyFiles.vue'
import Validation from '@/views/Validation.vue'
import Register from '@/views/Register.vue'
import ViewIgsn from '@/views/ViewIgsn.vue'
import UpdateIgsn from '@/views/UpdateIgsn.vue'
import ASearch from '@/views/ASearch.vue'
import User from '@/views/User.vue'
import News from '@/views/News.vue'
import ChangeLog from '@/views/ChangeLog.vue'

import { USERNAME } from '@/js/constants'

Vue.use(VueRouter)

const PUBLIC_PATHS = ['/changelog', '/validation', '/validation/', '/', '/login', '/aide', '/aide/', '/home', '/home/', '/logout', '/logout/', '/asearch', '/asearch/']

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: ''
    }
  },
  {
    path: '/home',
    name: 'Home2',
    component: Home,
    meta: {
      title: ''
    }
  },
  {
    path: '/private',
    name: 'Private',
    component: Private,
    meta: {
      title: 'My samples'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: 'News'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      title: 'User page'
    }
  },
  {
    path: '/myfiles',
    name: 'MyFiles',
    component: MyFiles,
    meta: {
      title: 'My files'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register sample'
    }
  },
  {
    path: '/asearch',
    name: 'ASearch',
    component: ASearch,
    meta: {
      title: 'Search samples'
    }
  },
  {
    path: '/validation',
    name: 'Validation',
    component: Validation,
    meta: {
      title: 'Validation'
    }
  },
  {
    path: '/updateigsn/:id',
    name: 'UpdateIgsn',
    component: UpdateIgsn,
    props: true,
    meta: {
      title: 'Update sample '
    }
  },
  {
    path: '/igsn/:id',
    name: 'Igsn',
    component: ViewIgsn,
    props: true,
    meta: {
      title: 'Sample '
    }
  },
  {
    path: '/meta/:id',
    name: 'Meta',
    component: ViewIgsn,
    props: true,
    meta: {
      title: 'Sample '
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: ''
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/changelog',
    name: 'ChangeLog',
    component: ChangeLog,
    meta: {
      title: 'ChangeLog'
    }
  },
  {
    path: '/aide',
    name: 'Aide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Aide.vue'),
    meta: {
      title: 'Help'
    }
  }
]

const router = new VueRouter({
  routes
})

function unAuthenticatedAndPrivatePage (path) {
  // returns true if page needs auth
  var res = false
  console.log('path=', path)
  console.log('PUBLIC_PATHS.includes(path)=', PUBLIC_PATHS.includes(path))

  if (!PUBLIC_PATHS.includes(path) && !(USERNAME in window.localStorage)) {
    res = true
  }
  if ((path.search('/igsn/') !== -1) || (path.search('/meta/') !== -1)) {
    res = false
  }
  return res
}

router.beforeEach((to, from, next) => {
  console.log('to.path=', to.path)
  console.log('path.search("/igsn/")=', to.path.search('/igsn/'))

  if (unAuthenticatedAndPrivatePage(to.path)) {
    next(`/login?next=${to.path}`)
  } else {
    next()
  }
})

export default router
