<template>
    <v-form v-on:submit="handleLogin">
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="4"
          >
        <v-text-field v-model="username" label="Enter username"  :rules="usernameRules" :counter="20" required></v-text-field>
          </v-col>
</v-row>
<v-row  justify="center">
<v-col
            cols="12"
            md="4"
          >
        <v-text-field type="password" v-model="password" label="Enter password"  :rules="passwordRules" :counter="20" ></v-text-field>
</v-col>
</v-row>

<v-row justify="center">
<v-col
            cols="12"
            md="4"
          >

      <v-btn class="mr-4" style="left: 50%; transform: translateX(-50%)" type="submit">Login</v-btn>
      </v-col>
       </v-row>

       </v-container>

       <v-snackbar
v-model="snackbar"
      :timeout="timeout"
      :color="snackbarcolor"
    >
    {{messageRes}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    </v-form>

</template>

<script>

import { API_URL } from '@/js/constants.js'
import { apiClientCred } from '@/js/xhr-client.js'

export default {
  name: 'LoginForm',
  data () {
    return {
      messageRes: '',
      timeout: 1000,
      snackbar: false,
      snackbarcolor: 'green',
      username: '',
      password: '',
      nextPath: '/',
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length <= 20 || 'Password must be less than 20 characters'
      ],
      usernameRules: [
        v => !!v || 'Username is required',
        v => v.length <= 20 || 'Username must be less than 20 characters'
      ],
      errorMessage: ''
    }
  },
  async mounted () {
    await apiClientCred.get(API_URL + '/set-csrf-cookie/')
    this.updateAfterLoginNextPath()
  },
  methods: {
    handleLogin (event) {
      event.preventDefault()
      this.$store.dispatch('login', { username: this.username, password: this.password })
        .then(() => {
          console.log('this.nextPath=' + this.nextPath)

          this.$router.push(this.nextPath)
        })
        .catch(error => {
          console.error('bad password : ' + error)
          this.success = 'Bad password'
          this.messageRes = this.success
          this.snackbar = true
          this.snackbarcolor = 'red'
        })
    },
    updateAfterLoginNextPath () {
      if ('next' in this.$route.query) {
        this.nextPath = this.$route.query.next
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="postcss" scoped>

</style>
