<template>
<div>

<v-container  class="mt-4 ml-4">
  <v-btn-toggle v-model="ssearch" borderless>
  <v-btn class="mr-4" value="mapsearch" v-on:click="clickMap">Map search</v-btn>
   <v-btn  value="textsearch" v-on:click="clickTxt">Text search</v-btn>
   </v-btn-toggle>
  </v-container>

<MyLoader v-if="isLoading"  style="display:inline;" />
<MyLoader v-else  style="display:none;" />

<v-container id="mapsearch" v-if="mapSearch" fill-height>
  <v-row   class="justify-center align-center">
    <v-col>
 <form @submit.prevent="searchMapIgsn">
 <l-map  class="map" :center="center" :zoom="zoom" ref="map" @ready="doSomethingOnReady()">
  <l-tile-layer :url="url"></l-tile-layer>
  <l-marker  v-for="m in marks"  :key="m.id" :lat-lng="m.coordinates" v-on:click="yop(m.landingPage)">
   <l-icon ref="icon">
 <img class="restaurant-icon" :src="m.imageUrl" :title="m.title"/>
  </l-icon>
</l-marker>
   </l-map>
  </form>
  </v-col>
</v-row>
  <v-row>
  <v-col align="center">
        <v-btn class="mt-4" @click="clearAllLayers">Clear map</v-btn>
</v-col>
</v-row>
</v-container>

<v-container id="textsearch" v-if="!mapSearch" fill-height>
  <v-row   class="justify-center align-center">
    <v-col align="center">
<v-card width="400" class="mb-4 ml-8">
  <v-card-title>Search with text</v-card-title>
  <v-card-subtitle>(you can use several criterias)</v-card-subtitle>
<v-card-text>
    <form  @submit.prevent="searchIgsn">

 <v-text-field label="IGSN code" id="resourceIdentifier" v-model="resourceIdentifier"></v-text-field>
 <v-text-field label="Sample or Item name" id="name" v-model="name"></v-text-field>
<v-select
            :items="sample_type"
            v-model="selected_sample_type"
            label="Sample type"
          ></v-select>

 <v-text-field label="Sample comment" id="sample_comment" v-model="sample_comment"></v-text-field>
<v-text-field label="Current archive" id="current_archive" v-model="current_archive"></v-text-field>
<v-text-field label="Current archive contact" id="current_archive_contact" v-model="current_archive_contact"></v-text-field>
<v-row   class="justify-center align-center">
    <v-col align="center">
 <v-btn type="submit">Search</v-btn>
 </v-col>
 </v-row>

</form>
</v-card-text>
</v-card>

</v-col>
</v-row>
</v-container>

<div   v-if="!isLoading && searchDone">
<v-container fill-height>
        <v-row  class="justify-center align-center">
          <v-col align="center">

            <download-excel :data="filt" :fields="json_fields">
  Download Data
  <img width="25" height="25" src="../assets/download.png" />
</download-excel>

            <v-data-table
            ref="kk"
      :headers="headers"
      :items="igsns"
      :items-per-page="100"
      :search="search"
      @current-items="getFiltered"
      class="elevation-1"
    >
    <template v-slot:item="{ item }">
        <tr>
     <td><a :href="'/#/igsn/'+item.resourceIdentifier">View</a></td>

          <td>{{item.name}}</td>
            <td>{{item.resourceIdentifier}}</td>
               <td>{{ item.lastModified  | formatDate }}</td>
        </tr>
    </template>
    </v-data-table>
    </v-col>
    </v-row>

    <v-row class="mt-4 justify-center align-center">
  <v-col align="center">
    <v-text-field
    dense
    outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search on the results above"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
        </v-row>
    </v-container>
  </div>

</div>
</template>

<script>

import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'

import 'leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

import { MAIN_URL } from '@/js/constants'

import { getSearchMapIgsn, getSearchIgsn } from '@/js/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  name: 'ASearch',
  props: {
  },
  components: {
    MyLoader,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data: function () {
    return {
      searchDone: false,
      marks: [],
      mapSearch: true,
      editableLayers: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [42, 6],
      zoom: 6,
      pos: [],
      marker: [],
      igsns: [],
      filt: [],
      json_fields: {
        Name: 'name',
        IGSN: 'resourceIdentifier'
        /* Classif: {
          field: 'classificationses',
          callback: (value) => {
            if (value != null && value.length > 0) {
              console.log('v=', value)
              return `Class 1 : ${value[0].classification}`
            } else { return `Class 1 : ${value}` }
          }
        } */
      },
      // sample_type: ['Core', 'Core half round', 'Core piece', 'Core quarter round', 'Core section', 'Core section half', 'Core sub-piece', 'Core whole round', 'Cuttings'],
      sample_type: ['Automated', 'Core', 'Core catcher', 'Core half round', 'Core piece', 'Core quarter round', 'Core section', 'Core section half', 'Core slab', 'Core sub-piece', 'Core u-channel', 'Core whole round', 'Cuttings', 'Dredge', 'Foliage digestion', 'Foliage leaching', 'Forest floor digestion', 'Grab', 'Inapplicable', 'Individual sample', 'Individual sample in core', 'Litter fall digestion', 'Missing', 'Oriented core', 'Other', 'Outcrop', 'Petri dish dry deposition', 'Precipitation bulk', 'Rock powder', 'Stab', 'Site', 'Standard reference specimen', 'U-chanel', 'Unknown', 'Template', 'Terrestrial section', 'Thin section', 'Withheld'],

      selected_sample_type: '',
      resourceIdentifier: '',
      sample_comment: '',
      current_archive: '',
      current_archive_contact: '',
      name: '',
      isLoading: false,
      search: '',
      ssearch: 'mapsearch',
      success: '',
      response: '',
      params: '',
      headers: [
        { text: '', value: 'action' },
        { text: 'Name', value: 'name' },
        { text: 'Identifier', value: 'resourceIdentifier' },
        { text: 'Updated', value: 'lastModified' }
      ]

    }
  },
  computed: {
    lesmarkers: function () {
      var lesmarkers = []
      for (var i = 0; i < this.igsns.length; i++) {
        console.log(this.igsns[i])
        console.log(this.igsns[i].latitude, this.igsns[i].longitude)
        if (this.igsns[i].latitude != null && this.igsns[i].longitude != null) {
          lesmarkers.push({ id: i, title: 'IGSN : ' + this.igsns[i].resourceIdentifier + '\n Name : ' + this.igsns[i].name, imageUrl: require('@/assets/pin.png'), coordinates: [Number(this.igsns[i].latitude), Number(this.igsns[i].longitude)], landingPage: MAIN_URL + '/#/igsn/' + this.igsns[i].resourceIdentifier })

          // const marker = L.circleMarker().addTo(map)
        }
      }
      console.log('lesmarkers=', lesmarkers)
      return lesmarkers
    }
  },

  methods:
  {
    getFiltered: function () {
      if (this.$refs.kk) {
        console.log(this.$refs.kk)
        this.filt = this.$refs.kk.$children[0].filteredItems
        console.log('Filtered output=', this.filt) // output the filtered items
      }
    },
    clearAllLayers () {
      this.editableLayers.clearLayers()
      while (this.marks.length > 0) {
        this.marks.pop()
      }
    },
    clickMap (event) {
      console.log('clickMap')
      this.mapSearch = true
    },
    clickTxt (event) {
      console.log('clickTxt')
      this.mapSearch = false
    },

    yop (link) {
      console.log('link=', link)
      window.open(link)
    },

    searchMapIgsn () {
      console.log('this.pos=', this.pos)
      console.log('this.pos[0][1]=', this.pos[0][1])
      this.params = { p1: { lon: this.pos[0][0].lng, lat: this.pos[0][0].lat }, p2: { lon: this.pos[0][1].lng, lat: this.pos[0][1].lat }, p3: { lon: this.pos[0][2].lng, lat: this.pos[0][2].lat }, p4: { lon: this.pos[0][3].lng, lat: this.pos[0][3].lat } }
      console.log('this.params=', this.params)
      this.isLoading = true

      getSearchMapIgsn(this.params).then(result => {
        this.igsns = result.data
        this.filt = this.igsns
        this.isLoading = false
        this.searchDone = true
        console.log('result.data 1=', result.data)
        console.log('result.data marker=', this.marker)
        for (var i = 0; i < this.igsns.length; i++) {
          if (this.igsns[i].latitude != null && this.igsns[i].latitude !== 'NaN' && this.igsns[i].longitude != null && this.igsns[i].longitude !== 'NaN') {
            this.marks.push({ id: i, title: 'IGSN : ' + this.igsns[i].resourceIdentifier + '\n Name : ' + this.igsns[i].name, imageUrl: require('@/assets/pin.png'), coordinates: [Number(this.igsns[i].latitude), Number(this.igsns[i].longitude)], landingPage: MAIN_URL + '/#/igsn/' + this.igsns[i].resourceIdentifier })
          }
        }
        console.log('this.marks=', this.marks)
      })
        .catch(error => {
          console.error('error getting file: ' + error)
        })
    },
    searchIgsn () {
      console.log('pos=', this.pos)
      this.params = { name: this.name, sampleType: this.selected_sample_type, resourceIdentifier: this.resourceIdentifier, sample_comment: this.sample_comment, current_archive: this.current_archive, current_archive_contact: this.current_archive_contact }
      this.isLoading = true

      getSearchIgsn(this.params).then(result => {
        this.igsns = result.data
        this.filt = this.igsns

        this.isLoading = false
        this.searchDone = true
        console.log('result.data 1=', result.data)
      })
        .catch(error => {
          console.error('error in getSearchIgsn: ' + error)
        })
    },
    doSomethingOnReady () {
      const map = this.$refs.map.mapObject
      const drawControl = new window.L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false,
          polygon: false, // disable or enable with true/false
          rectangle: true,
          circle: false,
          circlemarker: false,
          marker: false
        }
      })
      map.addControl(drawControl)
      this.editableLayers = new window.L.FeatureGroup().addTo(map)
      map.on(window.L.Draw.Event.CREATED, (e) => {
        const layer = e.layer
        // Do whatever else you need to. (save to db, add to map etc)
        this.editableLayers.addLayer(layer)
        console.log(e.layer.getLatLngs())
        this.pos = e.layer.getLatLngs()
        this.searchMapIgsn()
      })
    // })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.map {
  position:relative;
 height:500px;
width: 100%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.restaurant-icon {
    height: 5px;
    width: auto;
  }

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
