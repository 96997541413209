 <template>
  <div>
    <PrivateSamples/>
  </div>
</template>

<script>

import PrivateSamples from '@/views/PrivateSamples.vue'

export default {
  name: 'Private',
  components: {
    PrivateSamples
  }
}
</script>
<style lang="postcss" scoped>

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
