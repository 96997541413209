import { apiClient, apiClientCred, apiClient2 } from '@/js/xhr-client'

export function getCookie (name) {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    console.log('ok')
    console.log('doc.cook 2=' + document.cookie)
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export function loginAPI (username, password) {
  console.log('X-CSRFToken YOP=' + getCookie('csrftoken'))
  const result = apiClientCred.post(
    '/mylogin/',
    { username, password },
    {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        'Content-Type': 'application/json'
      }
    }
  )
  console.log('loginAPI result=', result)

  console.log('login result=', result)
  return result
}

export async function mint (params) {
  console.log('mint params=', params)
  const result = await apiClientCred.post('/mint/', params, {
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      'Content-Type': 'application/json'
    }
  })
  console.log('mint result=', result)
  return result
}

export async function update (spk, params) {
  console.log('update params=', params)
  const result = await apiClientCred.put('/uresource/' + spk + '/', JSON.stringify(params), {
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      'Content-Type': 'application/json'
    }
  })
  console.log('update result=', result)
  return result
}

export async function getMetaIGSN (id) {
  const result = await apiClientCred.get('/gresource/' + id + '/')
  console.log('getMetaIGSN result.data=', result.data)
  return result.data
}

export async function getResourceType () {
  const result = await apiClientCred.get('/rt/')
  console.log('getResourceType result.data=', result.data)
  return result.data
}

export async function getMaterial () {
  const result = await apiClientCred.get('/material/')
  console.log('getMaterial result.data=', result.data)
  return result.data
}

export async function getIdentifierType () {
  const result = await apiClientCred.get('/identifiertype/')
  console.log('getIdentifierType result.data=', result.data)
  return result.data
}

export async function getRelationType () {
  const result = await apiClientCred.get('/relationtype/')
  console.log('getRelationType result.data=', result.data)
  return result.data
}

export async function getCountries () {
  const result = await apiClientCred.get('/country/')
  console.log('getCountries result.data=', result.data)
  return result.data
}

export async function getCollectionMethod () {
  const result = await apiClientCred.get('/collectionmethod/')
  console.log('getCollectionMethod result.data=', result.data)
  return result.data
}

export async function getPhysiographicFeature () {
  const result = await apiClientCred.get('/physiographicfeature/')
  console.log('getPhysiographicFeature result.data=', result.data)
  return result.data
}

export async function getClassification () {
  const result = await apiClientCred.get('/classification/')
  console.log('getClassification result.data=', result.data)
  return result.data
}

export async function getNavigationType () {
  const result = await apiClientCred.get('/navigationtype/')
  console.log('getNavigationType result.data=', result.data)
  return result.data
}

// comprends pas pourquoi il faut ajouter /api ...
export async function getGeologicalResource (id) {
  console.log('COUCOU 1')
  const result = await apiClientCred.get('/geologicalresource/?nom=' + id)
  console.log('getGeologicalResource result.data=', result.data)
  return result.data
}

// comprends pas pourquoi il faut ajouter /api ...
export async function getCoredResource (id) {
  console.log('COUCOU 2')
  const result = await apiClientCred.get('/coredresource/?nom=' + id)
  console.log('getCoredResource result.data=', result.data)
  return result.data
}

export async function getPlatformType () {
  const result = await apiClientCred.get('/platformtype/')
  console.log('getPlatformType result.data=', result.data)
  return result.data
}

export async function getLaunchType () {
  const result = await apiClientCred.get('/launchtype/')
  console.log('getLaunchType result.data=', result.data)
  return result.data
}

export async function getGeologicalAge () {
  const result = await apiClientCred.get('/geologicalage/')
  console.log('getGeologicalAge result.data=', result.data)
  return result.data
}

export async function getPrefix () {
  const result = await apiClientCred.get('/prefix/')
  console.log('getPrefix result.data=', result.data)
  return result.data
}

export async function getChildrens (id) {
  const result = await apiClientCred.get('/getchildrens/' + id + '/')
  console.log('getChildrens result=', result)
  return result
}

export async function getAllChildrens () {
  const result = await apiClientCred.get('/getallchildrens/')
  console.log('getAllChildrens result=', result)
  return result
}

export async function getBroSis (id) {
  const result = await apiClientCred.get('/getbrosis/' + id + '/')
  console.log('getBroSisresult=', result)
  return result
}

export async function getSearchMapIgsn (params) {
  const result = await apiClient.get('/searchmap/', { params: params })
  console.log('getSearchMapIgsnresult=', result)
  return result
}

export async function getSearchIgsn (params) {
  const result = await apiClientCred.get('/searchtxt/', { params: params })
  console.log('getSearchIgsn=', result)
  return result
}

export async function getAllPrivateIgsnsAPI (page, pageSize) {
  const result = await apiClientCred.get('/gresources/?page=' + page + '&page_size=' + pageSize)
  console.log('getAllPrivateIgsnsAPI result=', result)
  return result
}

export async function getAllPrivateIgsnsAPI2 (page, pageSize, sortBy, sortDesc) {
  const result = await apiClientCred.get(
    '/gresources/?page=' +
      page +
      '&page_size=' +
      pageSize +
      '&sortBy=' +
      sortBy +
      '&sortDesc=' +
      sortDesc
  )
  console.log('getAllPrivateIgsnsAPI2 result=', result)
  return result
}

export async function getXLSFilesAPI () {
  const result = await apiClientCred.get('/getxlsfiles/')
  console.log('getAllXLSFilesAPI result=', result)
  return result
}

export async function getCSRFToken () {
  const result = await apiClient2.get('/csrf')
  console.log('getCSRFToken result=', result)
  return result
}

export async function postCheckXLS (params, token) {
  const result = await apiClient2.post('/checkXLS', params, {
    headers: {
      'X-CSRF-Token': token,
      'Content-Type': 'application/json'
    }
  })
  console.log('postCheckXLS result=', result)
  return result
}

export async function getNumberTotalCodes () {
  const result = await apiClient.get('/nbresources/')
  console.log('getNumberTotalCodes result=', result)
  return result
}

export async function getAllPublicIgsnForMap () {
  const result = await apiClient.get('/allformap/')
  console.log('getAllPublicIgsnForMap result=', result)
  return result
}
export async function getAllResourceIdentifier () {
  const result = await apiClientCred.get('/getallresourceidentifier/')
  console.log('getAllResourceIdentifier result=', result)
  return result
}

export async function setDeprecate (spk) {
  const result = await apiClientCred.put(
    '/setdep/' + spk + '/', JSON.stringify({}),
    {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        'Content-Type': 'application/json'
      }
    }
  )
  console.log('setDeprecate result=', result)
  return result
}

export async function getUserInfo () {
  const result = await apiClientCred.get('/userinfo/')
  console.log('getUserInfo result=', result)
  return result
}

export async function getUserPrefix () {
  const result = await apiClientCred.get('/userprefix/')
  console.log('getUserPrefix result=', result)
  return result
}

export async function getTotalCodes () {
  const result = await apiClientCred.get('/totalcodes/')
  console.log('getTotalCodes result=', result)
  return result
}
