<template>
<div>

<v-container  class="mt-4 ml-4">
  <v-btn-toggle v-model="sregister" borderless>
   <v-btn value="one" v-on:click="clickOneSample">One sample</v-btn>
   <v-btn value="xls" class="mr-4" v-on:click="clickXLS">Using XLS file</v-btn>
  </v-btn-toggle>
  </v-container>

<v-form v-if="registerOneSample && isReady" ref="form" @submit.prevent="registerIgsn" lazy-validation>

  <v-container fill-height>
  <v-row class="justify-center align-center">
    <v-col align="center">
      <span class="red--text">Required fields in red</span>
      <v-card width="700" class="mb-4">
        <v-card-title>Main metadata</v-card-title>
        <v-card-text>

<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6"> <span class="red--text">Prefix</span></v-col><v-col md="6">
<v-select
            :items="userPrefixes"
            item-text="nom"
            item-value="id"
            v-model="selected_prefix"
            label="Prefix"
            :rules="[(v) => !!v || 'Field required']"
      required
          ></v-select>

  </v-col></v-row>

  <v-row v-if="selected_prefix !=cnrsprefix  && selected_prefix !=toaeprefix " no-gutters><v-col md="6">Resource identifier</v-col><v-col md="6"><v-text-field label="resourceIdentifier" id="resourceIdentifier" v-model="resourceIdentifier"></v-text-field></v-col></v-row>

<v-row no-gutters><v-col md="6"><span class="red--text">Sample or Item name</span></v-col><v-col md="6"><v-text-field label="Name" id="name" :rules="[
        v => !!v || 'Field is required']" v-model="name"></v-text-field></v-col></v-row>

<!--
<v-row no-gutters><v-col md="6">IGSN parent</v-col><v-col md="6"><v-text-field label="Parent IGSN" id="parent_igsn" v-model="parent_igsn"></v-text-field></v-col></v-row>
-->

<v-row no-gutters><v-col md="6">IGSN parent</v-col>
  <v-col md="6">
<v-select
            :items="allResourceIdentifier"
             item-text="resourceIdentifier"
            item-value="id"
            v-model="selected_parentIdentifier"
            label="IGSN parent"
          ></v-select>
</v-col></v-row>

<v-row no-gutters><v-col md="6"><span class="red--text">Visibility</span></v-col><v-col md="6">

<v-select
            :items="isPublic2"
            v-model="selected_isPublic"
            label="Public ?"
              :rules="[(v) => !!v || 'Field required']"
      required
          ></v-select>

  </v-col></v-row>
<v-row v-if="selected_isPublic === 'Under umbargo' " no-gutters><v-col md="6">Publish date</v-col><v-col md="6"> <input type="date" v-model="publish_date" /></v-col></v-row>
<v-row><v-col md="6">Landing page (if not set, use default landing page. Must begin by https://.)</v-col><v-col md="6"><v-text-field label="Landing page" id="landingPage" v-model="landingPage"></v-text-field></v-col></v-row>

<v-row no-gutters><v-col md="6"><span class="red--text">Sample type</span></v-col><v-col md="6"><v-select
            :items="sample_type"
            v-model="selected_sample_type"
             item-text="nom"
            item-value="id"
            label="Sample type"
            :rules="[(v) => !!v || 'Field is required']"
      required
          ></v-select></v-col></v-row>
<v-row no-gutters><v-col md="6"><span class="red--text">Material</span></v-col><v-col md="6"> <v-select
            :items="material"
             item-text="nom"
            item-value="id"
            v-model="selected_material"
            label="Material"
            :rules="[(v) => !!v || 'Field is required']"
      required
          ></v-select></v-col></v-row>

<v-row no-gutters><v-col md="6">Classification</v-col><v-col md="6">
  <v-select
            :items="classification"
             item-text="nom"
            item-value="id"
            v-model="selected_classification"
            label="Classification"
          ></v-select>
 <input type="text" v-model="classificationUri" />
  </v-col></v-row>

<v-row no-gutters><v-col md="6">Field name</v-col><v-col md="6"><v-text-field label="Field name" id="field_name" v-model="field_name"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Age min</v-col><v-col md="6"><v-text-field label="Age min" id="age_min" v-model="age_min"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Age max</v-col><v-col md="6"><v-text-field label="Age max" id="age_max" v-model="age_max"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Age unit</v-col><v-col md="6"><v-text-field label="Age unit" id="age_unit" v-model="age_unit"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Geological age</v-col><v-col md="6">
<v-select
            :items="geological_age"
             item-text="nom"
            item-value="id"
            v-model="selected_geological_age"
            label="Geological age"
          ></v-select>

</v-col></v-row>
<v-row no-gutters><v-col md="6">Geological unit</v-col><v-col md="6"><v-text-field label="Geological unit" id="geological_unit" v-model="geological_unit"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection method</v-col><v-col md="6"> <v-select
            :items="collection_method"
             item-text="nom"
            item-value="id"
            v-model="selected_collection_method"
            label="Collection method"></v-select>
            <v-text-field label="Collection method description" id="collection_method_desc" v-model="collection_method_desc"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Size</v-col><v-col md="6"><v-text-field label="Size" id="size" v-model="size"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Size unit</v-col><v-col md="6"><v-text-field label="Size unit" id="size_unit" v-model="size_unit"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Comments</v-col><v-col md="6"><v-text-field label="Comments" id="comments" v-model="comments"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Purpose</v-col><v-col md="6"><v-text-field label="Purpose" id="purpose" v-model="purpose"></v-text-field></v-col></v-row>

<!--  relatedResourceses -->
<v-row no-gutters><v-col md="6">Related resources</v-col><v-col md="6">
<br>
      <v-btn @click="addResource">Add a resource</v-btn>
      <br>
  <div
      v-for="(rs, counter) in relatedResourceses"
      v-bind:key="counter">
        <label for="relatedResource">{{counter+1}}. Related resource :</label>
        <v-text-field label="Name" v-model="rs.relatedResource"  :rules="[(v) => !!v || 'Field is required']" ></v-text-field>
        <v-select
            :items="identifierType"
            item-text="nom"
            item-value="nom"
            v-model="rs.identifierType"
            label="Identifier type"
          ></v-select>

         <v-select
            :items="relationType"
            v-model="rs.relationType"
            item-text="nom"
            item-value="nom"
            label="Relation type"
          ></v-select>
<v-btn @click="deleteRelatedResource(counter)">Remove</v-btn>
  </div>

  </v-col></v-row>
</v-card-text>
</v-card>

<!-- Location -->

<v-card width="700" class="mb-4">
        <v-card-title>Location</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6">Latitude</v-col><v-col md="6"><v-text-field label="Latitude" id="latitude" v-model="latitude"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Latitude end</v-col><v-col md="6"><v-text-field label="Latitude end" id="latitude_end" v-model="latitude_end"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Longitude</v-col><v-col md="6"><v-text-field label="Longitude" id="longitude" v-model="longitude"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Longitude end</v-col><v-col md="6"><v-text-field label="Longitude end" id="longitude_end" v-model="longitude_end"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation</v-col><v-col md="6"><v-text-field label="Elevation" id="elevation" v-model="elevation"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation end </v-col><v-col md="6"><v-text-field label="Elevation end" id="elevation_end" v-model="elevation_end"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Elevation unit</v-col><v-col md="6"><v-text-field label="Elevation unit" id="elevation_unit" v-model="elevation_unit"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Bathy</v-col><v-col md="6"><v-text-field label="Bathy" id="bathy" v-model="bathy"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Bathy unit</v-col><v-col md="6"><v-text-field label="Bathy unit" id="bathy_unit" v-model="bathy_unit"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Navigation type</v-col><v-col md="6">

<v-select
            :items="navigation_type"
             item-text="nom"
            item-value="id"
            v-model="selected_navigation_type"
            label="Navigation type"
          ></v-select>

</v-col></v-row>
<v-row no-gutters><v-col md="6">Physiographic feature</v-col><v-col md="6">

  <v-select
            :items="physiographic_features"
             item-text="nom"
            item-value="id"
            v-model="selected_physiographic_feature"
            label="Physiographic feature"
          ></v-select>
 <!-- <v-text-field label="physiographic feature" id="physiographic_feature" v-model="physiographic_feature">
</v-text-field> -->
:

  <v-text-field label="physiographic feature name" id="physiographic_feature_name" v-model="physiographic_feature_name"></v-text-field>

</v-col></v-row>

<v-row no-gutters><v-col md="6">Locality</v-col><v-col md="6"><v-text-field label="Locality" id="locality" v-model="locality"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Locality description</v-col><v-col md="6"><v-text-field label="Locality description" id="locality_description" v-model="locality_description"></v-text-field></v-col></v-row>
<!-- <v-row no-gutters><v-col md="6">Country</v-col><v-col md="6"><v-text-field label="Country" id="country" v-model="country"></v-text-field></v-col></v-row>
-->

<v-row no-gutters><v-col md="6">Country</v-col><v-col md="6">
  <v-select
            :items="countries"
             item-text="nom"
            item-value="id"
            v-model="selected_country"
            label="Country"
          ></v-select>
  </v-col></v-row>

<v-row no-gutters><v-col md="6">Location description</v-col><v-col md="6"><v-text-field label="Location description" id="location_description" v-model="location_description"></v-text-field></v-col></v-row>

</v-card-text>
</v-card>

<v-card width="700" class="mb-4">
        <v-card-title>Collection</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6"><b>Parameter</b></v-col><v-col md="6"><b>Value</b></v-col></v-row>
<v-row no-gutters><v-col md="6">Cruise field program</v-col><v-col md="6"><v-text-field label="Cruise field program" id="cruise_field_prgrm" v-model="cruise_field_prgrm"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Platform type</v-col><v-col md="6">

<v-select
            :items="platform_type"
             item-text="nom"
            item-value="id"
            v-model="selected_platform_type"
            label="Platform type"
          ></v-select>

</v-col></v-row>
<v-row no-gutters><v-col md="6">Platform name</v-col><v-col md="6"><v-text-field label="Platform name" id="platform_name" v-model="platform_name"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Platform description</v-col><v-col md="6"><v-text-field label="Platform description" id="platform_descr" v-model="platform_descr"></v-text-field></v-col></v-row>

<v-row no-gutters><v-col md="6">Launch type</v-col><v-col md="6">

<v-select
            :items="launch_type"
             item-text="nom"
            item-value="id"
            v-model="selected_launch_type"
            label="Launch type"
          ></v-select>

</v-col></v-row>

<v-row no-gutters><v-col md="6">Launch platform name</v-col><v-col md="6"><v-text-field label="Launch platform name" id="launch_platform_name" v-model="launch_platform_name"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6"><span class="red--text">Collector</span></v-col><v-col md="6"><v-text-field label="Collector" id="collector" v-model="collector"  :rules="[ v => !!v || 'Field is required']"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collector detail</v-col><v-col md="6"><v-text-field label="Collector detail" id="collector_detail" v-model="collector_detail"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection date precision</v-col><v-col md="6"><v-text-field label="Collection date precision" id="collection_date_precision" v-model="collection_date_precision"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection start date</v-col><v-col md="6"><v-text-field label="Collection start date" id="collection_start_date" v-model="collection_start_date"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection end date</v-col><v-col md="6"><v-text-field label="Collection end date" id="collection_end_date" v-model="collection_end_date"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection time</v-col><v-col md="6"><v-text-field label="Collection time" id="collection_time" v-model="collection_time"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Collection time end</v-col><v-col md="6"><v-text-field label="Collection time end" id="collection_time_end" v-model="collection_time_end"></v-text-field></v-col></v-row>
</v-card-text>
</v-card>

<v-card width="700" class="mb-4">
        <v-card-title>Curation</v-card-title>
      <v-card-text>

<v-row no-gutters><v-col md="6">Current archive</v-col><v-col md="6"><v-text-field label="Current archive" id="current_archive" v-model="current_archive"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Current archive contact</v-col><v-col md="6"><v-text-field label="Current archive contact" id="current_archive_contact" v-model="current_archive_contact"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Original archive</v-col><v-col md="6"><v-text-field label="Original archive" id="original_archive" v-model="original_archive"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Original archive contact</v-col><v-col md="6"><v-text-field label="Original archive contact" id="original_archive_contact" v-model="original_archive_contact"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth min</v-col><v-col md="6"><v-text-field label="Depth min " id="depth_min" v-model="depth_min"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth max</v-col><v-col md="6"><v-text-field label="Depth max" id="depth_max" v-model="depth_max"></v-text-field></v-col></v-row>
<v-row no-gutters><v-col md="6">Depth scale</v-col><v-col md="6"><v-text-field label="Depth scale" id="depth_scale" v-model="depth_scale"></v-text-field></v-col></v-row>
</v-card-text>
</v-card>
</v-col>
</v-row>
</v-container>

    <v-btn class="mb-8 mr-4" style="left: 50%; transform: translateX(-50%)" type="submit">Register</v-btn>

    <div v-if="response">
      <h3>Response from server :</h3>
      <p v-if="success"> {{ success }}</p>
      <pre>{{ response }}</pre>
    </div>

<v-snackbar
v-model="snackbar"
      :timeout="timeout"
      :color="snackbarcolor"
    >
    {{messageRes}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-form>

  <!--  -------------------------------  -->
  <v-form  v-if="!registerOneSample" ref="form" @submit.prevent="handleXLS" lazy-validation>

<v-container>
<v-row no-gutters>

<v-col md="6"> To be ready soon<v-file-input placeholder="Please upload the XLS file to check"  v-model="checkXLS"    :rules="rules" ></v-file-input></v-col>
  <v-col md="6"><v-btn @click="handleXLS" disabled>Submit</v-btn></v-col>
  </v-row>
</v-container>
</v-form>

<div v-if="response">
    <h3>Response from server :</h3>
    <p v-if="success"> {{ success }}</p>
    <pre>{{ response }}</pre>
  </div>

<v-snackbar
v-model="snackbar"
    :timeout="timeout"
    :color="snackbarcolor"
  >
  {{messageRes}}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!--  -------------------------------  -->

</div>
</template>

<script>

import { mint, getUserPrefix, getPrefix, getResourceType, getMaterial, getCountries, getClassification, getCollectionMethod, getPhysiographicFeature, getPlatformType, getNavigationType, getGeologicalAge, getLaunchType, getAllResourceIdentifier, getIdentifierType, getRelationType } from '@/js/api'

export default {
  name: 'RegisterSample',
  props: {
  },
  /* computed: {
    user: (state) => state.user
  }, */
  data: function () {
    return {
      userPrefixes: [],
      allResourceIdentifier: [],
      selected_parentIdentifier: null,
      isReady: false,
      sregister: 'one',
      registerOneSample: true,
      snackbar: false,
      snackbarcolor: 'green',
      messageRes: '',
      params: {},
      name: null,
      succes: '',
      response: '',
      rules: [
        v => !!v || 'File is required'],
      checkXLS: null,
      selected_material: null,
      selected_sample_type: null,
      resourceIdentifier: '',
      publish_date: null,
      landingPage: null,
      defaultLandingPage: null,
      success: '',
      countries: [],
      selected_country: null,
      material: [],
      sample_type: [],
      classification: [],

      selected_classification: null,
      classificationUri: null,
      physiographic_features: [],
      selected_physiographic_feature: null,
      isPublic: [{ id: 1, value: 'Publicly visible' }, { id: 2, value: 'Private' }, { id: 3, value: 'Under umbargo' }],
      isPublic2: ['Publicly visible', 'Private', 'Under umbargo'],
      selected_isPublic: null,
      lesprefixes: ['CNRS', 'TOAE'],
      prefixes: [],
      selected_prefix: { id: 9999, nom: 'xxxxxxxx' },
      comments: '',
      ispublic2: null,
      parent_igsn: '',
      field_name: null,
      age_min: null,
      age_max: null,
      age_unit: null,
      geological_age: [],
      selected_geological_age: null,
      geological_unit: null,
      collection_method: [],
      selected_collection_method: null,
      collection_method_desc: '',
      size: null,
      size_unit: null,
      sample_comment: null,
      purpose: null,

      // relatedResources
      relatedResourceses: [],
      selected_identifierType: null,
      selected_relationType: null,
      // identifierType: ['Other', 'ARK', 'DOI', 'Handle', 'IGSN', 'ISBN', 'ISNI', 'ISSN', 'LSID', 'ORCID', 'PURL', 'URL', 'URN', 'VIAF', 'arXiv', 'bibcode', 'EAN13', 'EISSN', 'ISTC', 'PMID', 'LISSN', 'UPC'],
      identifierType: [],
      // relationType: ['IsDerivedFrom', 'IsSourceOf', 'IsAggregateOf', 'IsMemberOf', 'HasReferenceResource', 'IsIdenticalTo', 'IsDocumentedBy', 'Participates', 'HasDigitalRepresentation', 'IsDigitalRepresentationOf', 'HasSamplingFeature', 'IsSamplingFeatureOf'],
      relationType: [],

      // location
      latitude: null,
      latitude_end: null,
      longitude: null,
      longitude_end: null,
      elevation: null,
      elevation_end: null,
      elevation_unit: null,
      bathy: null,
      bathy_unit: null,
      navigation_type: [],
      selected_navigation_type: null,
      physiographic_feature: null,
      physiographic_feature_name: null,
      locality: null,
      locality_description: null,
      country: null,
      location_description: null,

      // collection
      cruise_field_prgrm: null,
      platform_type: [],
      selected_platform_type: null,

      platform_name: null,
      platform_descr: null,
      launch_platform_name: null,
      launch_type: [],
      selected_launch_type: null,

      collector: null,
      collector_detail: null,
      collection_date_precision: null,
      collection_start_date: null,
      collection_end_date: null,
      collection_time: null,
      collection_time_end: null,

      // curation
      current_archive: null,
      current_archive_contact: null,
      original_archive: null,
      original_archive_contact: null,
      depth_min: null,
      depth_max: null,
      depth_scale: null,
      timeout: 2000,
      cnrsprefix: null,
      toaeprefix: null
    }
  },
  created () {
    getPrefix().then(result => {
      console.log('result getPrefix=', result)
      this.prefixes = result
      this.cnrsprefix = this.prefixes.find(x => x.nom === 'CNRS').id
      this.toaeprefix = this.prefixes.find(x => x.nom === 'TOAE').id
    }).catch(error => {
      console.error('Error in getPrefix ' + error)
    })

    getUserPrefix().then(result => {
      console.log('result getUserPrefix=', result)
      this.userPrefixes = result.data
    }).catch(error => {
      console.error('Error in getUserPrefix ' + error)
    })

    getAllResourceIdentifier().then(result => {
      console.log(result)
      this.allResourceIdentifier = result.data
    })
      .catch(error => {
        console.error('Error in getAllResourceIdentifier : ' + error)
      })

    getMaterial().then(result => {
      console.log('result getMaterial=', result)
      this.material = result
    }).catch(error => {
      console.error('Error in getMaterial ' + error)
    })

    getResourceType().then(result => {
      console.log('result getResourceType=', result)
      this.sample_type = result
    }).catch(error => {
      console.error('Error in getResourceType ' + error)
    })

    getCountries().then(result => {
      console.log('countries=', result)
      this.countries = result
    })
      .catch(error => {
        console.error('Error in getCountries : ' + error)
      })

    getIdentifierType().then(result => {
      console.log('identifierType=', result)
      this.identifierType = result
    })
      .catch(error => {
        console.error('Error in getIdentifierType : ' + error)
      })

    getRelationType().then(result => {
      console.log('relationType=', result)
      this.relationType = result
    })
      .catch(error => {
        console.error('Error in getRelationType : ' + error)
      })

    getCollectionMethod().then(result => {
      console.log('collection_method=', result)
      this.collection_method = result
    })
      .catch(error => {
        console.error('Error in getCollectionMethod : ' + error)
      })

    getClassification().then(result => {
      console.log('classification=', result)
      this.classification = result
    })
      .catch(error => {
        console.error('Error in getClassification : ' + error)
      })

    getPhysiographicFeature().then(result => {
      console.log('physiographic feature=', result)
      this.physiographic_feature = result
    })
      .catch(error => {
        console.error('Error in getClassification : ' + error)
      })

    getNavigationType().then(result => {
      console.log('navigation type=', result)
      this.navigation_type = result
    })
      .catch(error => {
        console.error('Error in getNavigationType : ' + error)
      })

    getLaunchType().then(result => {
      console.log('launch type=', result)
      this.launch_type = result
    })
      .catch(error => {
        console.error('Error in getLaunchType : ' + error)
      })

    getGeologicalAge().then(result => {
      console.log('geological age=', result)
      this.geological_age = result
    })
      .catch(error => {
        console.error('Error in getGeologicalAge : ' + error)
      })

    getPlatformType().then(result => {
      console.log('platform type=', result)
      this.platform_type = result
      this.isReady = true
    })
      .catch(error => {
        console.error('Error in getPlatformType : ' + error)
      })
  },

  methods:
  {
    handleXLS () {
      /* decommenter qd ok
      if (!this.$refs.form.validate()) {
        this.messageRes = 'File required'
        this.snackbar = true
        this.snackbarcolor = 'orange'
      } else {
        this.params = { sub: 1, tuser: this.user }
        const payload = JSON.stringify(this.params)
        const bufferObject = Buffer.from(payload, 'utf-8')

        const file = new FormData()
        file.append('params', bufferObject)

        console.log('this.checkXLS=', this.checkXLS)
        console.log('this.checkXLS.type=', this.checkXLS.type)
        if (this.checkXLS.type !== 'application/vnd.ms-excel') {
          this.snackbar = true
          this.snackbarcolor = 'red'
          this.messageRes = 'It must be a XLS file'
          return false
        }
        file.append('file', this.checkXLS)
        console.log('file=', file)
        postCheckXLS(file).then(result => {
          console.log('File OK')
          console.log('result=', result)
          this.messageRes = result.data.message
          this.snackbar = true
          this.snackbarcolor = 'green'
        }).catch(error => {
          console.error('Error in postCheckXLS ' + error)
          console.log('error.response=', error.response)
          console.log('error.response.data=', error.response.data)
          this.messageRes = error.response.data.message
          this.snackbar = true
          this.snackbarcolor = 'red'
        })
      }
      */
    },
    clickOneSample (event) {
      console.log('clickOneSample')
      this.registerOneSample = true
    },
    clickXLS (event) {
      console.log('clickXLS')
      this.registerOneSample = false
    },
    addResource () {
      this.relatedResourceses.push({
        relatedResource: '',
        identifierType: '',
        relationType: ''
      })
    },
    deleteRelatedResource (counter) {
      this.relatedResourceses.splice(counter, 1)
    },
    registerIgsn () {
      if (this.$refs.form.validate()) {
        console.log(' avant this.publish_date=', this.publish_date)
        console.log('this.selected_isPublic=', this.selected_isPublic)
        console.log('this.selected_prefix=', this.selected_prefix)
        console.log(' this.prefixes[this.selected_prefix]=', this.prefixes.find(x => x.id === this.selected_prefix))

        this.ispublic2 = true
        if (this.selected_isPublic === 'Publicly visible') {
          console.log('yop3')
          this.ispublic2 = true
          this.publish_date = null
        } else if (this.selected_isPublic === 'Private') { // private
          this.ispublic2 = false
          this.publish_date = null
        } else { // under umbargo
          this.ispublic2 = false
        }
        console.log(' this.publish_date=', this.publish_date)
        console.log('this.selected_isPublic=', this.selected_isPublic)
        console.log('isPublic2=', this.ispublic2)

        console.log('relatedResourceses=', this.relatedResourceses)
        if (this.selected_prefix && (this.selected_prefix.nom === 'CNRS' || this.selected_prefix.nom === 'TOAE')) {
          this.resourceIdentifier = null
        }

        if (this.landingPage == null || this.landingPage === '') {
          this.landingPage = 'USE DEFAULT LANDING PAGE'
          this.defaultLandingPage = 'true'
        } else {
          this.defaultLandingPage = 'false'
        }

        if (this.selected_parentIdentifier != null) { this.parentIgsn = this.allResourceIdentifier.find(x => x.id === this.selected_parentIdentifier).resourceIdentifier }

        this.params = {
          resourceIdentifier: this.resourceIdentifier,
          name: this.name,
          prefix: this.prefixes.find(x => x.id === this.selected_prefix),
          user_code: this.selected_prefix,
          visibility: this.selected_isPublic,
          isPublic: this.ispublic2,
          logDate: { eventType: 'registered' },
          defaultLandingPage: this.defaultLandingPage,
          landingPage: this.landingPage,
          material: this.material.find(x => x.id === this.selected_material),
          relatedResourceses: this.relatedResourceses,
          resourceType: this.sample_type.find(x => x.id === this.selected_sample_type),

          parentIgsn: this.parentIgsn,

          publishDate: this.publish_date,
          fieldName: this.field_name,
          ageMin: this.age_min,
          ageMax: this.age_max,
          ageUnit: this.age_unit,
          geologicalAge: this.geological_age.find(x => x.id === this.selected_geological_age),
          geologicalUnit: this.geological_unit,
          size: this.size,
          sizeUnit: this.size_unit,
          resourceComment: this.sample_comment,
          purpose: this.purpose,

          // location
          latitude: this.latitude,
          latitudeEnd: this.latitude_end,
          longitude: this.longitude,
          longitudeEnd: this.longitude_end,
          elevation: this.elevation,
          elevationEnd: this.elevation_end,
          elevationUnit: this.elevation_unit,
          bathy: this.bathy,
          bathyUnit: this.bathy_unit,
          navigationType: this.navigation_type.find(x => x.id === this.selected_navigation_type),
          physiographicFeature: this.physiographic_feature.find(x => x.id === this.selected_physiographic_feature),

          physiographic_feature_name: this.physiographic_feature_name,
          location: this.locality,
          localityDescription: this.locality_description,
          country: this.countries.find(x => x.id === this.selected_country),
          locationDescription: this.location_description,

          // collection
          cruiseFieldPrgm: this.cruise_field_prgrm,
          platformType: this.platform_type.find(x => x.id === this.selected_platform_type),
          launchType: this.launch_type.find(x => x.id === this.selected_launch_type),

          platformName: this.platform_name,
          platformDescription: this.platform_descr,
          launchPlatformName: this.launch_platform_name,
          collector: this.collector,
          collectorDetail: this.collector_detail,
          collectionDatePrecision: this.collection_date_precision,
          collectionStartDate: this.collection_start_date,
          collectionEndDate: this.collection_end_date,
          collectionTime: this.collection_time,
          collectionTimeEnd: this.collection_time_end,

          // curation
          currentArchive: this.current_archive,
          currentArchiveContact: this.current_archive_contact,
          originalArchive: this.original_archive,
          originalArchiveContact: this.original_archive_contact,
          depthMin: this.depth_min,
          depthMax: this.depth_max,
          depthScale: this.depth_scale,
          classification: this.classification.find(x => x.id === this.selected_classification),
          collectionMethod: this.collection_method.find(x => x.id === this.selected_collection_method),
          collectionMethodDesc: this.collection_method_desc,
          physiographicFeatureName: this.physiographic_feature_name
        }

        mint(this.params)
          .then(result => {
            console.log(result.data)
            this.response = result.data
            this.success = 'IGSN successfully registered'
            this.messageRes = this.success
            this.snackbar = true
            this.snackbarcolor = 'green'
          //  }
          })
          .catch(error => {
            console.error('Error in  mint : ' + error)
            this.success = 'ERROR'
            this.messageRes = this.success
            this.snackbar = true
            this.snackbarcolor = 'red'
          })
      } else {
        this.messageRes = 'Field(s) required'
        this.snackbar = true
        this.snackbarcolor = 'orange'
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.loader{
    position : fixed;
    z-index: 9999;
    background : url( "../assets/load.gif" ) 50% 50% no-repeat;
    top : 0px;
    left : 0px;
    height : 100%;
    width : 100%;
    cursor : wait;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
