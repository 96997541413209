<template>

  <VueMarkdown :source="txt"></VueMarkdown>

</template>

<script>

import VueMarkdown from 'vue-markdown-v2'
import theChangeLog from '../assets/CHANGELOG.md'

export default {
  name: 'ChangeLog',
  props: {
  },
  components: { VueMarkdown },
  data: function () {
    return {
      txt: ''
    }
  },
  created () {
    this.txt = theChangeLog
  }

}
</script>

<style>

</style>
