<template>

  <div>

<v-data-table
      :headers="headers2"
      :items="igsns2"
      :sort-by="['creationDate']"
      :sort-desc="[true]"
      :items-per-page="100"
      class="elevation-1"
    >

    <template v-slot:item="{ item }">
        <tr>
            <td><a :href="item.urlOut">{{ item.filenameOut }}</a></td>
            <td>{{ item.creationDate | formatDate}}</td>
        </tr>
    </template>

    </v-data-table>

<br>
</div>

</template>

<script>

import { API_URL } from '@/js/constants'
import { getXLSFilesAPI } from '@/js/api'

export default {
  name: 'MyFiles',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      igsns2: [],
      headers2: [
        { text: 'File out', value: 'filenameOut' },
        { text: 'Updated', value: 'creationDate' }],
      api: API_URL
    }
  },
  methods:
  {

  },
  mounted () {
    console.log(API_URL)
    getXLSFilesAPI().then(result2 => {
      this.igsns2 = result2.data
      console.log('this.igsns2=', this.igsns2)
    }).catch(error => {
      console.error('Error in getXLSFilesAPI ' + error)
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
