<template>
<div class="loader"></div>
</template>

<script>
export default {
  name: 'MyLoader',
  props: {
    loading: { type: Boolean }
  },
  methods:
  {
  }
}

</script>

<style scoped>

.loader{
    position : fixed;
    z-index: 9999;
    background : url( "../assets/load.gif" ) 50% 50% no-repeat;
    top : 0px;
    left : 0px;
    height : 100%;
    width : 100%;
    cursor : wait;
}
</style>
