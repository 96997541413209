<template>
<div>

    <LoginForm/>

    </div>
</template>

<script>

import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  props: {
  },
  components: {
    LoginForm
  },
  data () {
    return {
      name: 'Login Page'
    }
  }
}
</script>
