<template>
  <div>
    <RegisterSample/>
  </div>
</template>

<script>

import RegisterSample from '@/views/RegisterSample.vue'

export default {
  name: 'Register',
  components: {
    RegisterSample
  }
}
</script>

<style lang="postcss" scoped>

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
