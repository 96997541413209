<template>

<div> Les news </div>

</template>

<script>
export default {
  name: 'News'

}
</script>

<style>

</style>
