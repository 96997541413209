import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueExcelXlsx from 'vue-excel-xlsx'
import JsonExcel from 'vue-json-excel'
import moment from 'moment'

// eslint-disable-next-line
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false
Vue.config.devtools = true
// Vue.config.baseUrl = '/igsn/vue/'
Vue.use(VueExcelXlsx)
Vue.component('downloadExcel', JsonExcel)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(value).format('DD-MM-YYYY')
  }
})

new Vue({
  router,
  store,
  beforeCreate () {
    console.log('in before create')
    this.$store.commit('initialiseStore')
  },
  vuetify,
  render: h => h(App)
}).$mount('#app')
