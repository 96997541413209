<template>
<div>
   Bye bye
    </div>
</template>

<script>
export default {
  name: 'Logout',
  props: {
    msg: String
  },
  components: {
  },
  data () {
    return {}
  }
}
</script>
